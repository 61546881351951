<template>
	<div class='sl-login-modal-bg'>
		<div class='sl-login-modal'>
			<div class='sl-login-modal-container'>
				<div class='sl-login-title-container'>
					<img src="../images/logo.svg" alt="alpha" class='sl-login-title-icon'>
				</div>
				<div class='sl-login-modal-title'>重置密码</div>

				<div class='sl-login-modal-field-label'>邮箱</div>
				<div class='sl-login-email-field-container'>
					<input class='sl-login-input-email' type="email" placeholder='请在此输入您的邮箱' v-model="this.email">
					<div class='sl-login-send-vcode-btn disable-select' @click="this.sendVcode">
						{{ this.sendEmailCountDown < 0 ? '发送验证码' : this.sendEmailCountDown + '秒后重发' }}
					</div>
				</div>
				<div class='sl-login-modal-field-label'>验证码</div>
				<input class='sl-login-input' type="text" placeholder='请在此输入验证码' v-model="this.vcode"/>
				<div class='sl-login-modal-field-label'>新密码</div>
				<input class='sl-login-input' type="password" placeholder='请在此输入密码' v-model="this.password"/>
				<div class='sl-login-modal-field-label'>确认新密码</div>
				<input class='sl-login-input' type="password" placeholder='请在此确认密码' v-model="this.confirmPassword"/>
				<div class='sl-login-modal-new-user' @click="this.goLogin">我有账号，去登录</div>
				<!--				<div class='sl-login-modal-new-user'>忘记密码</div>-->
				<div class='sl-login-btn-container'>
					<!--					<div class='sl-login-btn-cancel disable-select' @click='this.onHideRegisterModal'>取消-->
					<!--					</div>-->

					<a-button type="primary" size="large" @click="onResetPasswordConfirm">
						确认
					</a-button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import {postRequest, verifyEmail} from '@/utils/methods'

export default {
	name: "ResetPassword",
	data() {
		return {
			email: '',
			vcode: '',
			password: '',
			confirmPassword: '',
			sendEmailCountDown: -1,
			countDownInterval: null
		}
	},
	methods: {
		goLogin() {
			this.$emit('onLogin');
		},
		onResetPasswordConfirm() {
			if (!this.email) {
				this.$message.error('请输入邮箱')
				return
			}
			if (!verifyEmail(this.email)) {
				this.$message.error('请输入正确的邮箱')
				return
			}
			if (!this.vcode) {
				this.$message.error('请输入验证码')
				return
			}
			if (!this.password) {
				this.$message.error('请输入密码')
				return
			}
			if (!this.confirmPassword) {
				this.$message.error('请确认密码')
				return
			}
			if (this.password !== this.confirmPassword) {
				this.$message.error('两次输入的密码不一致')
				return
			}
			postRequest('airport/auth/resetPassword', {
				email: this.email,
				vcode: this.vcode,
				password: this.password
			}, (result, data) => {
				if (result === 'success') {
					this.$message.success('密码重置成功!')
					this.$emit('onLogin');
				} else {
					if (data.code === 201) {
						this.$message.error('邮箱与验证码不匹配')
					} else if (data.code === 202) {
						this.$message.error('验证码过期')
					} else {
						this.$message.error('密码重置失败')
					}
				}
			})
		},
		sendVcode() {
			if (this.sendEmailCountDown >= 0) {
				return
			}
			if (!this.email) {
				this.$message.error('请输入邮箱')
				return
			}
			if (!verifyEmail(this.email)) {
				this.$message.error('请输入正确的邮箱')
				return
			}
			const that = this
			postRequest('airport/auth/sendVcode', {email: this.email}, (result, data) => {
				if (result === 'success') {
					this.$message.success('验证码已发出，如果没有收到请检查垃圾收件箱。')
					that.sendEmailCountDown = 60
					that.countDownInterval = setInterval(() => {
						that.sendEmailCountDown--
						if (that.sendEmailCountDown < 0 && that.countDownInterval) {
							clearInterval(that.countDownInterval)
							that.countDownInterval = null
						}
					}, 1000)
				} else {
					if(data && data.code === 201) {
						this.$message.error('邮箱格式不正确')
						return
					}
					if(data && data.code === 202) {
						this.$message.error('不支持的邮箱后缀')
						return
					}

					this.$message.error('验证码发送出错，请重试')
				}
			})
		}
	}
}
</script>

<style scoped>
input {
	color: #FF8900;
}

.sl-login-modal-field-label {
	font-size: 1.4em;
	margin-top: 0.4em;
	color: #FF8900;
}

.sl-login-modal {
	position: fixed;
	background-color: rgb(0, 0, 0);
	transform: translate(-50%, 0%);
	left: 50%;
	width: 48em;
	top: 5em;
	border-radius: 1em;
	/*border: 1px solid rgba(255, 137, 0, 0.44);*/
	box-shadow: 0 0 8px 8px rgba(255, 137, 0, 0.15);
}

.swiper-slide-active > .sl-avatar-name {
	text-shadow: 2px 2px 5px red;
}

.sl-login-modal-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 3em 3em;
}

.sl-login-modal-title {
	font-size: 1.6em;
	width: 100%;
	text-align: center;
	color: #FF8900;
}

.sl-login-send-vcode-btn {
	background-color: #ff8900;
	font-size: 1.2em;
	color: #3d3020;
	border-radius: 0.2em;
	padding: 0 1em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: fit-content;
	word-break: keep-all;
}

.sl-login-send-vcode-btn:active {
	transform: scale(0.9);
}

.sl-login-input {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.4em;
	background: transparent;
	margin-top: 0.4em;
	width: 100%;
	padding: 0 !important;
	color: #FF8900;
}

.sl-login-input-email {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.4em;
	background: transparent;
	margin-top: 0.4em;
	flex: 1 1;
}

input.sl-login-input:focus {
	border-bottom: 2px solid #FF8900;
}

input.sl-login-input-email:focus {
	border-bottom: 2px solid #FF8900;
}

.sl-login-email-field-container {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.sl-login-modal-bg {
	position: fixed;
	width: 100vw;
	height: 100vh;
	/*backdrop-filter: blur(8px) opacity(100%) brightness(90%);*/
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.2);
	top: 0;
}

.sl-login-btn-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-top: 1em;
	justify-content: end;
}

.sl-login-btn-confirm {
	background-color: #FF8900;
	font-size: 1.4em;
	color: #3D3020;
	padding: 0.4em 1.2em;
	border-radius: 0.2em;
	/*margin-right: 3em;*/
	cursor: pointer;
}

.sl-login-btn-cancel {
	background-color: #3D3020;
	font-size: 1.4em;
	color: #FF8900;
	padding: 0.4em 1.2em;
	border-radius: 0.2em;
	border: 1px solid #FF8900;
	margin-left: 3em;
	cursor: pointer;
}

.sl-login-btn-confirm:active, .sl-login-btn-cancel:active {
	transform: scale(0.9);
}

</style>