<template>
	<div class="sub-view-container">
		<Teleport to="body">
			<transition name="fade">
				<CreateTicketModal v-if="this.showCreateTicketModal === true"
				                   @close="this.onCreateTicketModalClose"
				                   @submitted="this.onCreateTicketModalSubmitted"></CreateTicketModal>
			</transition>
		</Teleport>
		<Teleport to="body">
			<transition name="fade">
				<TicketDetailModal v-if="this.showTicketDetailModal === true"
				                   @close="this.onCreateTicketModalClose"
				                   @ticketUpdated="this.onTicketUpdated"
				                   :ticketItem="this.selectedTicketItem"></TicketDetailModal>
			</transition>
		</Teleport>
		<sub-view-header :title="'工单反馈'"/>
		<div class="manual-page-container">
			<div class="create-ticket-btn-row">

				<div class="create-ticket-btn" @click="this.onCreateTicket">
					<img src="../../images/plus.svg" alt="plus"
					     class="btn-icon">创建工单
				</div>
				<div class="create-ticket-btn" @click="this.onRefreshTickets">
					<img src="../../images/refresh_dark.svg" alt="plus"
					     class="btn-icon">刷新
				</div>
			</div>

			<div class="empty-ticket-container" v-if="this.ticketList.length === 0">
				<div class="empty-ticket-wrapper">
					<img class="empty-ticket-image" src="../../images/empty.svg" alt="empty">
					<div class="empty-ticket-txt">无工单</div>
				</div>

			</div>

			<div class="ticket-list-container" v-if="this.ticketList.length > 0">
				<template v-for="item in this.ticketList" :key="item.ticketId">
					<div class="ticket-list-wrapper" @click="this.onTicketClicked(item)">
						<div class="check-ticket-detail">查看详情<img class="check-ticket-detail-image"
						                                              src="../../images/share.svg" alt="share"></div>
						<div class="ticket-subject">{{ item.subject }}</div>
						<div class="empty-ticket-status" :style="this.getStatusTextStyle(item)">
							{{ this.getTicketStateDisplay(item) }}
						</div>
						<div class="ticket-created-time">{{ item.createdTimeString }}</div>
					</div>
				</template>
			</div>

		</div>

	</div>
</template>

<script>
import {getSubmittedTickets} from '@/utils/methods';
import SubViewHeader from "../../components/SubViewHeader.vue";
import CreateTicketModal from "../../components/CreateTicketModal.vue";
import TicketDetailModal from "@/components/TicketDetailModal.vue";

export default {
	name: "ContactPage",
	components: {
		TicketDetailModal,
		SubViewHeader,
		CreateTicketModal
	},
	data() {
		return {
			showCreateTicketModal: false,
			showTicketDetailModal: false,
			selectedTicketItem: null,
			ticketList: []
		}
	},
	async mounted() {
		await this.getSubmittedTickets()
	},
	methods: {
		async onTicketUpdated() {
			await this.getSubmittedTickets()
		},
		async onRefreshTickets() {
			await this.getSubmittedTickets()
		},
		async getSubmittedTickets() {
			const {err, result} = await getSubmittedTickets()
			if (err === false && result?.code === 200) {
				this.ticketList = result?.msg.sort((a, b) => {
					return b.createdTimestamp - a.createdTimestamp
				})
			}
		},
		getStatusTextStyle(item) {
			if (item.ticketStatus === 'wait_for_reply') {
				return {
					'color': 'red'
				}
			}
			if (item.ticketStatus === 'replied') {
				return {
					'color': 'green'
				}
			}
			if (item.ticketStatus === 'closed') {
				return {
					'color': '#E6D5B8'
				}
			}
		},
		onCreateTicket() {
			this.showCreateTicketModal = true
		},
		onCreateTicketModalClose() {
			this.showCreateTicketModal = false
			this.showTicketDetailModal = false
		},
		onClickCustomerService() {
			setTimeout(() => {
				window.open('https://t.me/StarLinkVPNCustomerService', '_blank');
			})

		},
		onClickCustomerServiceGroup() {
			setTimeout(() => {
				window.open('https://t.me/StarLinkVPNCustomerServiceGroup', '_blank');
			})
		},
		onClickTwitter() {
			setTimeout(() => {
				window.open('https://twitter.com/NebulaBinary', '_blank');
			})
		},
		onEmailClick() {
			const email = 'george.gu@nebulabinary.com';
			const subject = '有要事相告';
			const body = 'Body text';

			setTimeout(() => {
				window.open(`mailto:${email}?subject=${subject}&body=${body}`);
			})
		},
		getTicketStateDisplay(item) {
			if (item.ticketStatus === 'wait_for_reply') {
				return '等待回复'
			}
			if (item.ticketStatus === 'replied') {
				return '已回复'
			}
			if (item.ticketStatus === 'closed') {
				return '已关闭'
			}
		},
		onTicketClicked(item) {
			this.selectedTicketItem = item
			this.showTicketDetailModal = true
		},
		async onCreateTicketModalSubmitted() {
			this.showCreateTicketModal = false
			await this.getSubmittedTickets()
		}
	}
}
</script>

<style scoped>
.check-ticket-detail {
	display: flex;
	flex-direction: row;
	position: absolute;
	top: 0.2em;
	right: 0.2em;
	color: #E6D5B8;
	cursor: pointer;
}

.check-ticket-detail-image {
	width: 1em;
	margin: 0 0.4em;
}

.ticket-subject {
	color: #E6D5B8;
	font-size: 1.2em;
	width: 100%;
	box-sizing: border-box;
	text-align: start;
}

.ticket-created-time {
	color: #E6D5B8;
	font-size: 1em;
	width: 100%;
	box-sizing: border-box;
	text-align: start;
}

.ticket-list-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: rgba(230, 213, 184, 0.2);
	padding: 1em;
	box-sizing: border-box;
	margin-bottom: 1em;
	border-radius: 0.2em;
	position: relative;
}

.manual-page-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: start;
	padding: 0 3em;
}

.empty-ticket-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.empty-ticket-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.ticket-list-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	box-sizing: border-box;
	padding: 1em 0;
	width: 100%;
}


.manual-page-panel-content-container {

}

.manual-collapse-header {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 900px) {
	.manual-page-container {
		padding: 0 0.8em !important;
	}
}


.empty-ticket-image {
	width: 10em;
	opacity: 0.4;
}

.empty-ticket-txt {
	color: #E6D5B8;
	opacity: 0.4;
	font-size: 1.2em;
}

.empty-ticket-status {
	font-size: 1em;
	text-align: start;
}

.contact-info-title {
	color: #E6D5B8;
	font-size: 1.5em;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 0.8em;
	cursor: pointer;
}

.create-ticket-btn {
	font-size: 1.2em;
	background-color: #E6D5B8;
	color: #2c3e50;
	padding: 0.2em 0.6em;
	border-radius: 0.2em;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 0.4em;
}

.create-ticket-btn:active {
	transform: scale(0.95);
}

.create-ticket-btn-row{
	display: flex;
	flex-direction: row;
}

.btn-icon {
	width: 1em;
	margin-right: 0.2em;
}

</style>