<template>
	<div class="statistic-visit-container">
		<div class="statistic-refresh-btn-container" @click="this.onRefreshClicked">
			<img src="../../../images/refresh.svg" alt="refresh" class="statistic-refresh-btn-img">
			<div class="statistic-refresh-btn-text">刷新</div>
		</div>
		<div class="visit-statistic-label">访问统计</div>
		<div class="visit-statistic-label">今日访问人数：{{ this.visitCountToday }}</div>
		<div class="visit-statistic-label">昨日访问人数：{{ this.visitCountYesterday }}</div>
		<div class="visit-statistic-label">本月访问人数：{{ this.visitCountThisMonth }}</div>
		<div class="visit-statistic-label">今日访问来源：</div>
		<div class="statistic-visit-sub-container">
			<div class="statistic-visit-sub-container-row">
				<div class="statistic-visit-sub-container-cell">域名</div>
				<div class="statistic-visit-sub-container-cell">点击</div>
				<div class="statistic-visit-sub-container-cell"><img src="../../../images/os/os_and.svg" alt="android" class="download-type-cell-img"></div>
				<div class="statistic-visit-sub-container-cell"><img src="../../../images/os/os_win.svg" alt="windows" class="download-type-cell-img"></div>
				<div class="statistic-visit-sub-container-cell"><img src="../../../images/os/os_macos.svg" alt="macos" class="download-type-cell-img"></div>
				<div class="statistic-visit-sub-container-cell"><img src="../../../images/os/os_ios.svg" alt="ios" class="download-type-cell-img"></div>
			</div>

			<template v-for="item in this.visitCountTodayClassifiedByUrl" :key="item.tag">

				<div class="statistic-visit-sub-container-row">
					<div class="statistic-visit-sub-container-cell">{{ item.tag }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.count }}</div>
					<div class="statistic-visit-sub-container-cell" style="color: #E45826FF;">{{ item.android }}</div>
					<div class="statistic-visit-sub-container-cell" style="color: #E45826FF;">{{ item.windows }}</div>
					<div class="statistic-visit-sub-container-cell" style="color: #E45826FF;">{{ item.mac }}</div>
					<div class="statistic-visit-sub-container-cell" style="color: #E45826FF;">{{ item.ios }}</div>
				</div>
			</template>
		</div>

		<div class="visit-statistic-label" style="color: #E45826FF;">今日访问设备类型：</div>
		<div class="download-type-container">
			<div class="download-type-container-row">
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_and.svg" alt="android">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_win.svg" alt="win">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_macos.svg" alt="mac">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_ios.svg" alt="ios">
				</div>
			</div>
			<div class="download-type-container-row">
				<div class="download-type-container-item">
					{{this.todayVisitType?.android}}
				</div>
				<div class="download-type-container-item">
					{{this.todayVisitType?.windows}}
				</div>
				<div class="download-type-container-item">
					{{this.todayVisitType?.mac}}
				</div>
				<div class="download-type-container-item">
					{{this.todayVisitType?.ios}}
				</div>
			</div>
		</div>

		<div class="visit-statistic-label">昨日访问来源：</div>
		<div class="statistic-visit-sub-container">
			<div class="statistic-visit-sub-container-row">
				<div class="statistic-visit-sub-container-cell">域名</div>
				<div class="statistic-visit-sub-container-cell">点击</div>
			</div>

			<template v-for="item in this.visitCountYesterdayClassifiedByUrl" :key="item.tag">

				<div class="statistic-visit-sub-container-row">
					<div class="statistic-visit-sub-container-cell">{{ item.tag }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.count }}</div>
				</div>
			</template>
		</div>

		<div class="visit-statistic-label" style="color: #E45826FF;">昨日访问设备类型：</div>
		<div class="download-type-container">
			<div class="download-type-container-row">
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_and.svg" alt="android">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_win.svg" alt="win">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_macos.svg" alt="mac">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_ios.svg" alt="ios">
				</div>
			</div>
			<div class="download-type-container-row">
				<div class="download-type-container-item">
					{{this.yesterdayVisitType?.android}}
				</div>
				<div class="download-type-container-item">
					{{this.yesterdayVisitType?.windows}}
				</div>
				<div class="download-type-container-item">
					{{this.yesterdayVisitType?.mac}}
				</div>
				<div class="download-type-container-item">
					{{this.yesterdayVisitType?.ios}}
				</div>
			</div>
		</div>

		<div class="visit-statistic-label">本月访问来源：</div>
		<div class="statistic-visit-sub-container">
			<div class="statistic-visit-sub-container-row">
				<div class="statistic-visit-sub-container-cell">域名</div>
				<div class="statistic-visit-sub-container-cell">点击</div>
			</div>

			<template v-for="item in this.visitCountThisMonthClassifiedByUrl" :key="item.tag">

				<div class="statistic-visit-sub-container-row">
					<div class="statistic-visit-sub-container-cell">{{ item.tag }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.count }}</div>
				</div>
			</template>
		</div>

		<div class="visit-statistic-label" style="color: #E45826FF;">本月访问设备类型：</div>
		<div class="download-type-container">
			<div class="download-type-container-row">
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_and.svg" alt="android">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_win.svg" alt="win">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_macos.svg" alt="mac">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_ios.svg" alt="ios">
				</div>
			</div>
			<div class="download-type-container-row">
				<div class="download-type-container-item">
					{{this.thisMonthVisitType?.android}}
				</div>
				<div class="download-type-container-item">
					{{this.thisMonthVisitType?.windows}}
				</div>
				<div class="download-type-container-item">
					{{this.thisMonthVisitType?.mac}}
				</div>
				<div class="download-type-container-item">
					{{this.thisMonthVisitType?.ios}}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	getRequestAsync,
	hideLoading,
	showLoading,
	visitClassifyByUrl,
	generateDeviceType
} from "@/utils/methods";
export default {
	name: "AirportTrafficStatisticView",
	components: {},
	data() {
		return {
			visitCountToday: 0,
			visitCountTodayClassifiedByUrl: [],
			visitCountThisMonth: 0,
			visitCountThisMonthClassifiedByUrl: [],
			visitCountYesterday: 0,
			visitCountYesterdayClassifiedByUrl: [],
			todayVisitType: {
				android: 0,
				ios: 0,
				windows: 0,
				mac: 0
			},
			yesterdayVisitType: {
				android: 0,
				ios: 0,
				windows: 0,
				mac: 0
			},
			thisMonthVisitType: {
				android: 0,
				ios: 0,
				windows: 0,
				mac: 0
			}
		}
	},
	async beforeMount() {
		await this.getTrafficInfo()
	},
	methods: {
		async getTrafficInfo () {
			showLoading()
			const result = await getRequestAsync('statistic/getAirportTrafficInfo')
			hideLoading()
			if (result?.code === 200) {
				this.visitCountToday = result.msg?.mergedTodayVisitList?.length
				this.visitCountYesterday = result.msg?.mergedYesterdayVisitList?.length
				this.visitCountThisMonth = result.msg?.mergedThisMonthVisitList?.length
				this.visitCountTodayClassifiedByUrl = visitClassifyByUrl(result.msg?.mergedTodayVisitList)
				this.visitCountYesterdayClassifiedByUrl = visitClassifyByUrl(result.msg?.mergedYesterdayVisitList)
				this.visitCountThisMonthClassifiedByUrl = visitClassifyByUrl(result.msg?.mergedThisMonthVisitList)
				this.todayVisitType = generateDeviceType(result.msg?.mergedTodayVisitList)
				this.yesterdayVisitType = generateDeviceType(result.msg?.mergedYesterdayVisitList)
				this.thisMonthVisitType = generateDeviceType(result.msg?.mergedThisMonthVisitList)
			}
		},
		async onRefreshClicked() {

			await this.getTrafficInfo()
		}
	}
}
</script>

<style scoped>

input.cs-input:focus {
	border-bottom: 2px solid #FF8900;
}


.statistic-visit-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
}

.statistic-visit-sub-container{
	display: table;
	align-items: start;
	color: #E6D5B8;
	padding-bottom: 0.2em;
}

.statistic-visit-sub-container-row{
	display: table-row;
	align-items: start;
	color: #E6D5B8;
}

.statistic-visit-sub-container-cell{
	display: table-cell;
	align-items: start;
	color: #E6D5B8;
	text-align: start;
	padding-right: 0.8em;
}


</style>