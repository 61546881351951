<template>
	<div class="sub-view-header">
		<div class="sub-view-header-title disable-select">
			{{ title }}
		</div>
	</div>
</template>

<script>
export default {
	name: "SubViewHeader",
	props: ['title']
}
</script>

<style scoped>
.sub-view-header {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 50px;
	padding: 0 20px;
	background-color: transparent;
	width: 100%;
}

.sub-view-header-title {
	font-size: 1.6em;
	color: #FF8900;
	z-index: 1000;
}
</style>