
<template>
	<div>
		<a-tabs v-model:activeKey="mainTabKey">
			<a-tab-pane key="general" tab="概要">
				<GeneralAirportStatisticView></GeneralAirportStatisticView>
			</a-tab-pane>
			<a-tab-pane key="browse" tab="访问统计" force-render>
				<AirportTrafficStatisticView/>
			</a-tab-pane>
<!--			<a-tab-pane key="download" tab="下载统计">-->
<!--				<DownloadStatisticView></DownloadStatisticView>-->
<!--			</a-tab-pane>-->
<!--			<a-tab-pane key="register" tab="注册统计">-->
<!--				<RegisterStatisticView></RegisterStatisticView>-->
<!--			</a-tab-pane>-->
<!--			<a-tab-pane key="users" tab="用户统计">-->
<!--				<UserStatisticView></UserStatisticView>-->
<!--			</a-tab-pane>-->
<!--			<a-tab-pane key="payment" tab="付费统计">-->
<!--				<PaymentStatisticView></PaymentStatisticView>-->
<!--			</a-tab-pane>-->
		</a-tabs>
	</div>
</template>
<script>

import {defineComponent} from "vue";
import GeneralAirportStatisticView from "@/pages/views/airport/GeneralAirportStatisticView.vue";
import AirportTrafficStatisticView from "@/pages/views/airport/AirportTrafficStatisticView.vue";
import DownloadStatisticView from "@/pages/views/vpnStatistic/DownloadStatisticView.vue";
import GeneralStatisticView from "@/pages/views/vpnStatistic/GeneralStatisticView.vue";
import PaymentStatisticView from "@/pages/views/vpnStatistic/PaymentStatisticView.vue";
import UserStatisticView from "@/pages/views/vpnStatistic/UserStatisticView.vue";

export default{
	name: "StatisticPanel",
	components: {
		GeneralAirportStatisticView,
		AirportTrafficStatisticView
		// PaymentStatisticView,
		// GeneralStatisticView,
		// DownloadStatisticView,
		// TrafficStatisticView,
		// RegisterStatisticView
	},
	data() {
		return {
			mainTabKey: 'general',
		}
	}
}
</script>

<style scoped>

</style>