<template>
	<div class="sub-view-container">
		<sub-view-header :title="'推荐好友'"/>
	</div>

</template>

<script>
import SubViewHeader from "../../components/SubViewHeader.vue";
export default {
	name: "ReferFriendPage",
	components: {
		SubViewHeader
	}
}
</script>

<style scoped>

</style>