<template>
	<div class="usdt-payment-page">
		<div v-if="isPaymentCompleted !== true" class="usdt-payment-page-container">
			<div class="usdt-payment-page-title">支付指南</div>
			<div class="usdt-payment-manual-item">1、复制任一个转账地址；</div>
			<div class="usdt-payment-manual-item">2、打开您的数字钱包，向该地址转入 <span style="font-weight: bold;">{{amount}}</span> USDT;</div>
			<div class="usdt-payment-manual-item">3、点击 “我已支付” 完成验证。</div>
			<div style="width: 100%;font-weight: bold;">请注意：<br/>区块确认需要几分钟，您可以多次点击 “我已支付” 直到验证通过。</div>

			<div class="usdt-payment-address-container">
				<div class="usdt-payment-address-title">
					<div class="usdt-payment-address-title-wrapper">
						<div>
							网络类型：
						</div>
						<div>
							<img src="../../images/crypto/bsc.svg" alt="bsc" style="width: 1.2em;margin-right: 0.2em;"/>BEP20 （币安智能链）
						</div>

				</div>
				</div>
				<div class="usdt-payment-address-content">

					<span>
					转账地址：
				</span>
					<a-button type="primary" size="small" style="color: #191123FF;" @click="copyBscAddress">
						复制地址
					</a-button>
				</div>
				<span class="usdt-payment-address enable-select">{{ this.usdtBSCPaymentAddress }}</span>
				<div class="usdt-payment-qr-wrapper">

					<canvas id="rqBSCAddress"/>
				</div>
			</div>
			<div class="usdt-payment-address-container">
				<div class="usdt-payment-address-title">
					<div class="usdt-payment-address-title-wrapper">
						<div>
							网络类型：
						</div>
						<div>
							<img src="../../images/crypto/trx.svg" alt="bsc" style="width: 1.2em;margin-right: 0.2em;"/>TRC20 （波场链）
						</div>

				</div>
				</div>
				<div class="usdt-payment-address-content">

					<span>
					转账地址：
				</span>
					<a-button type="primary" size="small" style="color: #191123FF;" @click="copyTrc20Address">
						复制地址
					</a-button>
				</div>
				<span class="usdt-payment-address enable-select">{{ this.usdtTRC20PaymentAddress }}</span>
				<div class="usdt-payment-qr-wrapper">

					<canvas id="rqTRXAddress"/>
				</div>
			</div>

			<div class="usdt-payment-tool-bar">
				<a-popconfirm
					title="订单取消后转账记录将会被清除，确认取消?"
					ok-text="我再想想"
					cancel-text="确认"
					class="airport-dashboard-popconfirm"
					@confirm="cancel"
					@cancel="cancelOrder">
					<a-button type="primary" size="large" style="color: #FF8900;background-color: #191123FF">
						取消订单
					</a-button>
				</a-popconfirm>

				<a-button type="primary" size="large" style="color: #ffffff;" @click="verifyTransaction">
					我已支付
				</a-button>
			</div>
		</div>
		<div v-if="isPaymentCompleted === true" class="usdt-payment-page-success">
			<img src="../../images/success.svg" alt="success" style="width: 10em;margin-top: 2em;">
			<div class="usdt-payment-complete-text" style="margin-top: 1em;">订单已支付，感谢支持。</div>
			<a-button type="primary" size="large" style="margin-top: 1em;" @click="goBack">
				返回首页
			</a-button>
		</div>
	</div>
</template>

<script>
import {postRequest} from "@/utils/methods";
import QRCode from "qrcode";
import copy from 'copy-to-clipboard';

import Antd from 'ant-design-vue';

export default {
	name: "USDTPaymentPage",
	data() {
		return {
			usdtBSCPaymentAddress: '加载中...',
			usdtTRC20PaymentAddress: '加载中...',
			amount: '',
			transactionId: '',
			isPaymentCompleted: false
		}
	},
	mounted() {
		// 获取名为id的URL参数
		const id = this.$route.query.id;
		this.transactionId = id
		if (!id) {
			this.$message.error('获取支付id失败');
			return
		}
		postRequest('airport/payment/getAddressByTransactionId', {paymentId: id}, (result, data) => {
			if (data.code === 203) {
				this.$message.info('该笔订单已支付');

				this.isPaymentCompleted = true
				return
			}
			if (result !== 'success') {
				this.$message.error('获取支付地址失败');
				return
			}



			if (data.code === 200 && data.msg.bscAddress && data.msg.trxAddress && data.msg.usdtAmount) {

				this.usdtBSCPaymentAddress = data.msg.bscAddress
				this.usdtTRC20PaymentAddress = data.msg.trxAddress
				const bscQrCanvas = document.getElementById('rqBSCAddress')
				QRCode.toCanvas(bscQrCanvas, data.msg.bscAddress, function (error) {
					if (error) console.error(error)
					console.log('success!');
				})
				const trxQrCanvas = document.getElementById('rqTRXAddress')
				QRCode.toCanvas(trxQrCanvas, data.msg.trxAddress, function (error) {
					if (error) console.error(error)
					console.log('success!');
				})
				this.amount = data.msg.usdtAmount

			} else {
				this.$message.error('获取支付地址失败');
			}
		})
	},
	methods: {
		copyTrc20Address() {

			const copyResult = copy(this.usdtTRC20PaymentAddress);
			if (copyResult ===true) {
				this.$message.success({
					content: '复制成功',
					duration: 3 // 设置持续时间为3秒
				});
			} else {

				this.$message.success({
					content: '复制失败',
					duration: 3 // 设置持续时间为3秒
				});
			}


		},
		copyBscAddress() {

			const copyResult = copy(this.usdtBSCPaymentAddress);
			if (copyResult ===true) {
				this.$message.success({
					content: '复制成功',
					duration: 3 // 设置持续时间为3秒
				});
			} else {

				this.$message.success({
					content: '复制失败',
					duration: 3 // 设置持续时间为3秒
				});
			}

		},
		cancelOrder() {
			this.$router.go(-1);
		},
		verifyTransaction () {
			if (!this.transactionId) {
				this.$message.error('获取支付id失败');
				return
			}
			this.$message.info('验证中...');
			postRequest('airport/payment/verifyTransaction', {transactionId: this.transactionId}, (result, data) => {
				if (result !== 'success') {
					this.$message.error('验证支付失败');
					return
				}
				if(data.code === 206) {
					this.$message.error('未发现转账记录');
					return
				}
				if(data.code === 207) {
					this.$message.error('转账金额不足');
					return
				}
				if (data.code === 200) {
					this.$message.success('验证成功');
					this.isPaymentCompleted = true
					return
				}
				this.$message.success('验证失败');
			})
		},
		goBack() {
			this.$router.push({path: '/dashboard/mysub'})
		}
	}
}

</script>

<style scoped>
.usdt-payment-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	height: 100vh;
	font-size: 20px;
	color: #fff;
	position: fixed;
	background-color: #191123;
	overflow-y: scroll;
	padding-bottom: 3em;
	overflow-x: hidden;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.usdt-payment-page-title {
	font-size: 1.2em;
	padding: 1em 0;
	color: #E6D5B8;
	width: 100%;
	text-align: center;
}

@media only screen and (max-width: 900px) {

	.usdt-payment-page-container {
		width: 100% !important;
		padding: 0 1em;
	}

	.usdt-payment-address {
		width: 100%;
		word-break: break-word;
		text-align: start;
	}

	.usdt-payment-address-title-wrapper{
		flex-direction: column !important;
		align-items: start !important;
	}

	.usdt-payment-address-title{
		margin-bottom: 0.4em !important;
	}

	.usdt-payment-address{
		margin-bottom: 0.4em !important;
	}

}
.usdt-payment-qr-wrapper{
	width: 100%;
	text-align: center;
}

.usdt-payment-page-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	width: 32em;
	color: #E6D5B8;

}

.usdt-payment-address-container {
	display: flex;
	flex-direction: column;
	color: #FF8900;
	align-items: start;
	border: 1px solid;
	border-radius: 0.2em;
	padding: 0.8em;
	width: 100%;
	margin-top: 1em;
}

.usdt-payment-address-title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.usdt-payment-address-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.usdt-payment-address {

}

.usdt-payment-tool-bar {
	margin-top: 1em;
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.usdt-payment-address-title-wrapper{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.usdt-payment-complete-text{
	color: #FF8900;
	font-size: 1.4em
}

.usdt-payment-manual-item{
	text-align: start;
	word-wrap: break-word;
}


</style>