<template>
<!--	<div class="sl-send-email-modal-bg-container"event.stopPropagation()">-->
		<div class='sl-send-email-modal-bg' @click="onBgClick($event)">
			<div class='sl-send-email-modal'>
				<div class='sl-send-email-modal-container'>
					<div class='sl-send-email-modal-close-container'>
						<img src="../images/close.svg" style="width: 1.2em;margin: 0.1em;" alt="close" @click="onClose">
					</div>
					<div class='sl-send-email-modal-title'>请输入您的邮箱地址，我们会将Apple ID发送至您的邮箱</div>

					<div class='sl-send-email-email-field-container'>
						<input class='sl-send-email-input-email' type="email" placeholder='请在此输入您的邮箱' v-model="this.email">
						<div class='sl-send-email-send-vcode-btn disable-select' @click="this.sendEmail">
							发送
						</div>
					</div>
				</div>
			</div>
		</div>
<!--	</div>-->


</template>

<script>
import {postRequest, verifyEmail} from '@/utils/methods'

export default {
	name: "SendAppleIdModal",
	data() {
		return {
			email: '',
			vcode: '',
			password: '',
			confirmPassword: '',
			sendEmailCountDown: -1,
			countDownInterval: null
		}
	},
	methods: {
		sendEmail() {
			if (this.sendEmailCountDown >= 0) {
				return
			}
			if (!this.email) {
				this.$message.error('请输入邮箱')
				return
			}
			if (!verifyEmail(this.email)) {
				this.$message.error('请输入正确的邮箱')
				return
			}
			this.$emit('sendEmail', this.email)
			// const that = this
			// postRequest('airport/auth/sendVcode', {email: this.email}, (result, data) => {
			// 	if (result === 'success') {
			// 		this.$message.success('验证码已发出')
			// 		that.sendEmailCountDown = 60
			// 		that.countDownInterval = setInterval(() => {
			// 			that.sendEmailCountDown--
			// 			if (that.sendEmailCountDown < 0 && that.countDownInterval) {
			// 				clearInterval(that.countDownInterval)
			// 				that.countDownInterval = null
			// 			}
			// 		}, 1000)
			// 	} else {
			// 		if (data && data.code === 201) {
			// 			this.$message.error('邮箱格式不正确')
			// 			return
			// 		}
			// 		if (data && data.code === 202) {
			// 			this.$message.error('不支持的邮箱后缀')
			// 			return
			// 		}
			//
			// 		this.$message.error('验证码发送出错，请重试')
			// 	}
			// })
		},
		onClose() {
			this.$emit('close')
		},
		onBgClick(event) {
			event.stopPropagation()
		}
	}
}
</script>

<style scoped>
input {
	color: #FF8900;
}

.sl-send-email-modal {
	width: 90%;
	position: fixed;
	background-color: rgb(0, 0, 0);
	transform: translate(-50%, 0%);
	left: 50%;
	top: 5em;
	border-radius: 0.4em;
	/* border: 1px solid rgba(255, 137, 0, 0.44); */
	box-shadow: 0 0 8px 8px rgba(255, 137, 0, 0.15);
	padding: 0.8em;
}

.swiper-slide-active > .sl-avatar-name {
	text-shadow: 2px 2px 5px red;
}

.sl-send-email-modal-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.sl-send-email-modal-title {
	font-size: 1.2em;
	width: 100%;
	text-align: left;
	color: #FF8900;
}

.sl-send-email-send-vcode-btn {
	background-color: #ff8900;
	font-size: 1em;
	color: #3d3020;
	border-radius: 0.2em;
	padding: 0 0.3em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: fit-content;
	word-break: keep-all;
}

.sl-send-email-send-vcode-btn:active {
	transform: scale(0.9);
}

.sl-send-email-input-email {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.2em;
	background: transparent;
	margin-top: 0.4em;
	flex: 1 1;
}

input.sl-send-email-input:focus {
	border-bottom: 2px solid #FF8900;
}

input.sl-send-email-input-email:focus {
	border-bottom: 2px solid #FF8900;
}

.sl-send-email-email-field-container {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.sl-send-email-modal-bg {
	position: fixed;
	width: 100vw;
	/* backdrop-filter: blur(8px) opacity(100%) brightness(90%); */
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.2);
	top: 0;
	left: 0;
	height: 100vh;
}

.sl-send-email-modal-close-container {
	width: 100%;
	text-align: right;
}

.sl-send-email-modal-bg-container{
	width: 100vw;
	height: 100vh;
}

</style>