<template>
	<div class="statistic-visit-container">
		<div class="statistic-refresh-btn-container" @click="this.onRefreshClicked">
			<img src="../../../images/refresh.svg" alt="refresh" class="statistic-refresh-btn-img">
			<div class="statistic-refresh-btn-text">刷新</div>
		</div>
		<div class="visit-statistic-label">下载统计</div>
		<div class="visit-statistic-label">今日下载数：{{ this.downloadCountToday }}</div>
		<div class="visit-statistic-label">昨日下载数：{{ this.downloadCountYesterday }}</div>
		<div class="visit-statistic-label">本月下载数：{{ this.downloadCountThisMonth }}</div>

		<div class="visit-statistic-label">今日下载来源：</div>
		<div class="statistic-visit-sub-container">
			<div class="statistic-visit-sub-container-row">
				<div class="statistic-visit-sub-container-cell">域名</div>
				<div class="statistic-visit-sub-container-cell">下载</div>
				<div class="statistic-visit-sub-container-cell"><img src="../../../images/os/os_and.svg" alt="android" class="download-type-cell-img"></div>
				<div class="statistic-visit-sub-container-cell"><img src="../../../images/os/os_win.svg" alt="windows" class="download-type-cell-img"></div>
				<div class="statistic-visit-sub-container-cell"><img src="../../../images/os/os_macos.svg" alt="macos" class="download-type-cell-img"></div>
				<div class="statistic-visit-sub-container-cell"><img src="../../../images/os/os_ios.svg" alt="ios" class="download-type-cell-img"></div>
			</div>

			<template v-for="item in this.downloadCountTodayClassifiedByUrl" :key="item.tag">

				<div class="statistic-visit-sub-container-row">
					<div class="statistic-visit-sub-container-cell">{{ item.tag }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.count }}</div>
					<div class="statistic-visit-sub-container-cell" style="color: #E45826FF;">{{ item.android }}</div>
					<div class="statistic-visit-sub-container-cell" style="color: #E45826FF;">{{ item.windows }}</div>
					<div class="statistic-visit-sub-container-cell" style="color: #E45826FF;">{{ item.mac }}</div>
					<div class="statistic-visit-sub-container-cell" style="color: #E45826FF;">{{ item.ios }}</div>
				</div>
			</template>
		</div>

		<div class="visit-statistic-label" style="color: #E45826FF;">今日下载系统类型：</div>
		<div class="download-type-container">
			<div class="download-type-container-row">
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_and.svg" alt="android">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_win.svg" alt="win">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_macos.svg" alt="mac">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_ios.svg" alt="ios">
				</div>
			</div>
			<div class="download-type-container-row">
				<div class="download-type-container-item">
					{{this.todayDownloadType?.android}}
				</div>
				<div class="download-type-container-item">
					{{this.todayDownloadType?.windows}}
				</div>
				<div class="download-type-container-item">
					{{this.todayDownloadType?.mac}}
				</div>
				<div class="download-type-container-item">
					{{this.todayDownloadType?.ios}}
				</div>
			</div>
		</div>


		<div class="visit-statistic-label">昨日下载来源：</div>
		<div class="statistic-visit-sub-container">
			<div class="statistic-visit-sub-container-row">
				<div class="statistic-visit-sub-container-cell">域名</div>
				<div class="statistic-visit-sub-container-cell">下载</div>
			</div>

			<template v-for="item in this.downloadCountYesterdayClassifiedByUrl" :key="item.tag">

				<div class="statistic-visit-sub-container-row">
					<div class="statistic-visit-sub-container-cell">{{ item.tag }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.count }}</div>
				</div>
			</template>
		</div>


		<div class="visit-statistic-label" style="color: #E45826FF;">昨日下载系统类型：</div>
		<div class="download-type-container">
			<div class="download-type-container-row">
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_and.svg" alt="android">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_win.svg" alt="win">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_macos.svg" alt="mac">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_ios.svg" alt="ios">
				</div>
			</div>
			<div class="download-type-container-row">
				<div class="download-type-container-item">
					{{this.yesterdayDownloadType?.android}}
				</div>
				<div class="download-type-container-item">
					{{this.yesterdayDownloadType?.windows}}
				</div>
				<div class="download-type-container-item">
					{{this.yesterdayDownloadType?.mac}}
				</div>
				<div class="download-type-container-item">
					{{this.yesterdayDownloadType?.ios}}
				</div>
			</div>
		</div>

		<div class="visit-statistic-label">本月下载来源：</div>
		<div class="statistic-visit-sub-container">
			<div class="statistic-visit-sub-container-row">
				<div class="statistic-visit-sub-container-cell">域名</div>
				<div class="statistic-visit-sub-container-cell">下载</div>
			</div>

			<template v-for="item in this.downloadCountThisMonthClassifiedByUrl" :key="item.tag">

				<div class="statistic-visit-sub-container-row">
					<div class="statistic-visit-sub-container-cell">{{ item.tag }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.count }}</div>
				</div>
			</template>
		</div>

		<div class="visit-statistic-label" style="color: #E45826FF;">本月下载系统类型：</div>
		<div class="download-type-container">
			<div class="download-type-container-row">
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_and.svg" alt="android">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_win.svg" alt="win">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_macos.svg" alt="mac">
				</div>
				<div class="download-type-container-item">
					<img class="download-type-container-img" src="../../../images/os/os_ios.svg" alt="ios">
				</div>
			</div>
			<div class="download-type-container-row">
				<div class="download-type-container-item">
					{{this.thisMonthDownloadType?.android}}
				</div>
				<div class="download-type-container-item">
					{{this.thisMonthDownloadType?.windows}}
				</div>
				<div class="download-type-container-item">
					{{this.thisMonthDownloadType?.mac}}
				</div>
				<div class="download-type-container-item">
					{{this.thisMonthDownloadType?.ios}}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	getRequestAsync,
	hideLoading,
	showLoading,
	visitClassifyByUrl,
	generateDownloadType
} from "@/utils/methods";

export default {
	name: "DownloadStatisticView",
	components: {},
	data() {
		return {
			downloadCountToday: 0,
			downloadCountTodayClassifiedByUrl: [],
			downloadCountThisMonth: 0,
			downloadCountThisMonthClassifiedByUrl: [],
			downloadCountYesterday: 0,
			downloadCountYesterdayClassifiedByUrl: [],
			todayDownloadType: {
				android: 0,
				ios: 0,
				windows: 0,
				mac: 0
			},
			yesterdayDownloadType: {
				android: 0,
				ios: 0,
				windows: 0,
				mac: 0
			},
			thisMonthDownloadType: {
				android: 0,
				ios: 0,
				windows: 0,
				mac: 0
			}
		}
	},
	async beforeMount() {
		await this.getDownloadInfo()
	},
	methods: {
		async getDownloadInfo () {
			showLoading()
			const result = await getRequestAsync('statistic/getDownloadInfo')
			hideLoading()
			if (result?.code === 200) {
				this.downloadCountThisMonth = result.msg?.mergedThisMonthDownloadList?.length
				this.downloadCountToday = result.msg?.mergedTodayDownloadList?.length
				this.downloadCountYesterday = result.msg?.mergedYesterdayDownloadList?.length
				this.downloadCountTodayClassifiedByUrl = visitClassifyByUrl(result.msg?.mergedTodayDownloadList)
				this.downloadCountThisMonthClassifiedByUrl = visitClassifyByUrl(result.msg?.mergedThisMonthDownloadList)
				this.downloadCountYesterdayClassifiedByUrl = visitClassifyByUrl(result.msg?.mergedYesterdayDownloadList)
				this.todayDownloadType = generateDownloadType(result.msg?.mergedTodayDownloadList)
				this.yesterdayDownloadType = generateDownloadType(result.msg?.mergedYesterdayDownloadList)
				this.thisMonthDownloadType = generateDownloadType(result.msg?.mergedThisMonthDownloadList)
			}
		},
		async onRefreshClicked() {

			await this.getDownloadInfo()
		}
	}
}
</script>
<style scoped>

.customer-service-page-container{
	padding: 1em;
}
.customer-service-page-container {
	min-height: 100vh;
	position: relative;
	background-color: #191123;
	overflow-x: hidden;
	width: 100vw;
}


.cs-menu-container {
	top: 0;
	left: 0;
	flex: 1;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.2);
	padding: 1em 0.8em;
	overflow-y: scroll;
}


.topic-menu-item {
	display: flex;
	flex-direction: column;
	padding: 0.6em;
	border-bottom: 1px solid #E6D5B8;
	align-items: start;
}


.cs-main-container {
	display: flex;
	flex-direction: row;

	height: 100vh;
	width: 100vw;
	overflow-y: hidden;
}

.cs-topic-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	color: #E6D5B8;
}

input.cs-input:focus {
	border-bottom: 2px solid #FF8900;
}

.statistic-info-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 列宽度设置为相等的三列 */
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
}

.statistic-visit-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
}

.statistic-visit-sub-container{
	display: table;
	align-items: start;
	color: #E6D5B8;
	padding-bottom: 0.2em;
}

.statistic-visit-sub-container-row{
	display: table-row;
	align-items: start;
	color: #E6D5B8;
}

.statistic-visit-sub-container-cell{
	display: table-cell;
	align-items: start;
	color: #E6D5B8;
	text-align: start;
	padding-right: 0.8em;
}

.stat-grid-cell {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(230, 213, 184, 0.2);
}

.stat-cell-title {
	padding-top: 0.4em;
	color: var(--white-color);
}

.stat-content {
	font-size: 1.4em;
	color: var(--white-color);
}

@media only screen and (max-width: 900px) {
	.statistic-info-container {
		grid-template-columns: 1fr 1fr;
	}
}


</style>