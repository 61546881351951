<template>
	<div class='sl-login-modal-bg' @click="onRegisterBgClick($event)">
		<div class='sl-login-modal'>
			<div class='sl-login-modal-container'>
				<div class='sl-login-modal-title'>钱包设置</div>

				<div class='sl-login-modal-field-label'>邮箱</div>
				<div class='sl-login-email-field-container'>
					<input class='sl-login-input-email' type="email" placeholder='请在此输入您的邮箱' v-model="this.email" disabled>
					<div class='sl-login-send-vcode-btn disable-select' @click="this.sendVcode">
						{{ this.sendEmailCountDown < 0 ? '发送验证码' : this.sendEmailCountDown + '秒后重发' }}
					</div>
				</div>
				<div class='sl-login-modal-field-label'>验证码</div>
				<input class='sl-login-input' type="text" placeholder='请在此输入验证码' v-model="this.vcode"/>
				<div class='sl-login-modal-field-label'>trc20钱包地址</div>
				<input class='sl-login-input' type="text" placeholder='请在此输入trc20钱包地址' v-model="this.trc20WalletAddress"/>
				<div class='sl-login-modal-field-label'>bep20钱包地址</div>
				<input class='sl-login-input' type="text" placeholder='请在此输入bep20钱包地址' v-model="this.bep20WalletAddress"/>
				<div class='sl-login-btn-container'>
					<a-button type="primary" size="large" @click="onEditCancel"
					          style="color: #FF8900;background-color: #191123FF;margin-right: 0.5em;">
						取消
					</a-button>
					<a-button type="primary" size="large" @click="onEditConfirm">
						提交
					</a-button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import {getRequest, postRequest, verifyEmail,
	isValidTRC20Address,
	isValidBEP20Address} from "@/utils/methods";

export default {
	name: "EditWalletModal",
	data() {
		return {
			email: '',
			vcode: '',
			trc20WalletAddress: '',
			bep20WalletAddress: '',
			sendEmailCountDown: -1,
			countDownInterval: null
		}
	},
	props: ['userEmail'],
	mounted() {
		if (this.userEmail) {
			this.email = this.userEmail
		}
	},
	methods: {
		onEditConfirm() {
			if (!this.email) {
				this.$message.error('请输入邮箱')
				return
			}
			if (!this.vcode) {
				this.$message.error('请输入验证码')
				return
			}
			if (!this.trc20WalletAddress && !this.bep20WalletAddress) {
				this.$message.error('至少输入一个钱包地址')
				return
			}
			if (this.trc20WalletAddress && !isValidTRC20Address(this.trc20WalletAddress)) {
				this.$message.error('请输入正确的trc20钱包地址')
				return
			}
			if (this.bep20WalletAddress && !isValidBEP20Address(this.bep20WalletAddress)) {
				this.$message.error('请输入正确的bep20钱包地址')
				return
			}
			postRequest('airport/reference/setWalletAddress', {
				vcode: this.vcode,
				trc20Address: this.trc20WalletAddress,
				bep20Address: this.bep20WalletAddress
			}, (result, data) => {
				if (result === 'success') {
					this.$message.success('设置成功')
					this.trc20WalletAddress = data.msg.trc20WalletAddress
					this.bep20WalletAddress = data.msg.bep20WalletAddress

					this.$emit('onEditClose', this.trc20WalletAddress, this.bep20WalletAddress)
				} else {
					if (data.code === 201) {
						this.$message.error('邮箱已存在，请直接登录')
						return
					} else if (data.code === 202) {
						this.$message.error('至少输入一个钱包地址')
						return
					} else if (data.code === 203) {
						this.$message.error('验证码校验失败')
						return
					} else if (data.code === 204) {
						this.$message.error('验证码已被使用')
						return
					} else if (data.code === 205) {
						this.$message.error('验证码已过期')
						return
					} else if (data.code === 206) {
						this.$message.error('钱包地址不正确')
						return
					}
					this.$message.error('设置失败，请稍后重试')
				}
			})

		},
		goLogin() {
			this.$emit('goLogin');
		},
		sendVcode() {
			if (this.sendEmailCountDown >= 0) {
				return
			}
			if (!this.email) {
				this.$message.error('请输入邮箱')
				return
			}
			if (!verifyEmail(this.email)) {
				this.$message.error('请输入正确的邮箱')
				return
			}
			const that = this

			getRequest('airport/reference/sendSetWalletAddressVcode', (result, data) => {
				if (result === 'success') {
					this.$message.success('验证码已发出，如果没有收到请检查垃圾收件箱。')
					that.sendEmailCountDown = 60
					that.countDownInterval = setInterval(() => {
						that.sendEmailCountDown--
						if (that.sendEmailCountDown < 0 && that.countDownInterval) {
							clearInterval(that.countDownInterval)
							that.countDownInterval = null
						}
					}, 1000)
				} else {
					if (data.code === 201 || data.code === 300) {
						this.$message.error('验证失败，请登录')
						this.$emit('userVerifyFailed')
					} else if (data.code === 400) {
						this.$message.error('服务繁忙请稍后再试')
					}

				}
			})
		},
		onRegisterBgClick(event) {
			event.stopPropagation()

		},
		onEditCancel() {
			this.$emit('onEditCancel')
		}
	}
}
</script>

<style scoped>
input {
	color: #FF8900;
}

.sl-login-modal-field-label {
	font-size: 1.4em;
	margin-top: 0.4em;
	color: #FF8900;
}

.sl-login-modal {
	position: fixed;
	background-color: rgb(0, 0, 0);
	transform: translate(-50%, 0%);
	left: 50%;
	width: 48em;
	top: 5em;
	border-radius: 1em;
	/*border: 1px solid rgba(255, 137, 0, 0.44);*/
	box-shadow: 0 0 8px 8px rgba(255, 137, 0, 0.15);
}

.swiper-slide-active > .sl-avatar-name {
	text-shadow: 2px 2px 5px red;
}

.sl-login-modal-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 3em 3em;
}

.sl-login-modal-title {
	font-size: 1.6em;
	width: 100%;
	text-align: center;
	color: #FF8900;
}

.sl-login-send-vcode-btn {
	background-color: #ff8900;
	font-size: 1.2em;
	color: #3d3020;
	border-radius: 0.2em;
	padding: 0 1em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: fit-content;
	word-break: keep-all;
}

.sl-login-send-vcode-btn:active {
	transform: scale(0.9);
}

.sl-login-input {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.4em;
	background: transparent;
	margin-top: 0.4em;
	width: 100%;
	padding: 0 !important;
	color: #FF8900;
}

.sl-login-input-email {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.4em;
	background: transparent;
	margin-top: 0.4em;
	flex: 1 1;
}

input.sl-login-input:focus {
	border-bottom: 2px solid #FF8900;
}

input.sl-login-input-email:focus {
	border-bottom: 2px solid #FF8900;
}

.sl-login-email-field-container {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.sl-login-modal-bg {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.9);
	top: 0;
	left: 0;
}

.sl-login-btn-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-top: 1em;
	justify-content: end;
}

.sl-login-btn-confirm {
	background-color: #FF8900;
	font-size: 1.4em;
	color: #3D3020;
	padding: 0.4em 1.2em;
	border-radius: 0.2em;
	/*margin-right: 3em;*/
	cursor: pointer;
}

.sl-login-btn-cancel {
	background-color: #3D3020;
	font-size: 1.4em;
	color: #FF8900;
	padding: 0.4em 1.2em;
	border-radius: 0.2em;
	border: 1px solid #FF8900;
	margin-left: 3em;
	cursor: pointer;
}

.sl-login-btn-confirm:active, .sl-login-btn-cancel:active {
	transform: scale(0.9);
}

</style>