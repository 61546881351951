<template>
	<div class="manual-page-container">
		<SendEmailModal @close="onEmailModalClose" v-if="showSendEmailModal === true"
		                @sendEmail="onSendAppleId"></SendEmailModal>
		<RegisterModalSmall v-if="showRegisterModal===true" @registerSuccess="onRegisterSuccess" :toRegisterEmail="toRegisterEmail"
		                    @onRegCancel="onRegCancel" :referCode="referCode" :userEmail="toRegisterEmail"></RegisterModalSmall>
		<ConfirmModal v-if="showConfirmModal===true" @cancel="onConfirmModalCancel" @confirm="onConfirmModalConfirm"></ConfirmModal>
		<div :class="{'blur': this.showSendEmailModal === true || showRegisterModal===true || showConfirmModal===true}">
			<sub-view-header :title="'苹果手机使用说明'"/>

			<div class="manual-page-important-info"><strong>重要安全提示：</strong> <br/>
				请务必严格按照我们的教程步骤登录至 App Store，下载完成后请立即退出，切勿直接登录至您设备的 iCloud，否则可能会导致您的个人资料泄露或者设备被锁定，我们无法帮您恢复损失。
			</div>
			<div class="manual-page-reference">
				这里有两篇图文解释供您参考：<br/>
				<strong @click="howToLoginAppStore" class="manual-page-reference-link">《如何仅登录 App Store》</strong> 与
				<strong @click="howToEscapeSecurityQuestion" class="manual-page-reference-link">《如何跳过安全问题》</strong>
			</div>
			<div class="manual-page-forbid">
				严禁于 iCloud 设置内登录，您的设备会有被锁定勒索的风险。
			</div>
			<div class="manual-page-time-require">
				该客户端下载及配置大概需要您 5 分钟 的时间，分为 2 步，仅第一次使用需要配置。
			</div>
			<div class="manual-page-important-info">
				<strong>1、户端下载：</strong> <br/>
				Shadowrocket 是一个无法使用中国大陆 App Store 下载的的收费应用，我们为您提供外区 Apple ID 并且购买了该应用，您可以使用我们的共享 Apple ID 来下载。
				您需要将我们的共享 Apple ID 仅登录到 App Store 并且 选择不升级双重验证并跳过安全验证。<br/><br/>
			</div>

			<a-button type="primary" size="large" style="color: #FFFFFF; margin-bottom: 1em;" @click="getAppleId">
				获取Apple ID
			</a-button>
			<div class="manual-page-important-info">使用上方共享账户登录后，点击下方按钮下载 Shadowrocket</div>
			<a-button type="primary" size="large" style="color: #FFFFFF; margin-bottom: 1em;" @click="appStoreDownload">
				App Store下载
			</a-button>
			<div class="manual-page-important-info">
				（请在 Safari 浏览器上点击） 如果仍提示错误请在后台关闭并重新打开 App Store。下载 Shadowrocket 完成后请您立即退出登录。
			</div>
			<div class="manual-page-important-info">
				<strong>2、导入配置：</strong><br/>
				安装Shadowrocket完成后，请按照下面的方式导入配置。
			</div>
			<img src="../images/manual/ios.jpg" alt="mac manual" style="width: 100%;"/>
		</div>


	</div>
</template>

<script>
import SubViewHeader from "../components/SubViewHeader.vue";
import SendEmailModal from "../components/SendEmailModal.vue";
import RegisterModalSmall from "../components/RegisterModalSmall.vue";
import ConfirmModal from "../components/ConfirmModal.vue";
import {postRequest} from "@/utils/methods";

export default {
	name: "IosManualPage",
	components: {
		SubViewHeader,
		SendEmailModal,
		RegisterModalSmall,
		ConfirmModal
	},
	data() {
		return {
			showSendEmailModal: false,
			showRegisterModal: false,
			showConfirmModal: false,
			referCode: '',
			toRegisterEmail: ''
		}
	},
	mounted() {
		this.referCode = this.$route.query.referCode;
	},
	methods: {
		howToLoginAppStore() {
			setTimeout(() => {
				window.open('https://jingyan.baidu.com/article/d45ad148eabcc128542b807a.html', '_blank');
			})
		},
		howToEscapeSecurityQuestion() {
			setTimeout(() => {
				window.open('https://help.aneeo.store/index.php/14', '_blank');
			})
		},
		appStoreDownload() {
			setTimeout(() => {
				window.open('https://apps.apple.com/us/app/shadowrocket/id932747118', '_blank');
			})
		},
		getAppleId() {
			this.showSendEmailModal = true;
		},
		onEmailModalClose() {
			this.showSendEmailModal = false;
		},
		onSendAppleId(email) {
			postRequest('airport/auth/getAppleId', {email: email}, (result, data) => {
				if (result === 'success') {
					this.$message.success('Apple ID 已发送至您的邮箱，请注意查收');
					this.showSendEmailModal = false;
					this.showRegisterModal = false;
					return
				}
				if (data && data.code === 203) {
					this.$message.error('您的账号需要有可用的套餐时间以获取Apple ID');
					return
				}
				if (data && data.code === 202) {
					this.toRegisterEmail = email
					this.showSendEmailModal = false
					this.showConfirmModal = true
					return
				}
			})
		},
		onRegisterSuccess(email) {
			this.onSendAppleId(email)
		},
		onRegCancel() {
			this.showRegisterModal = false;
		},
		onConfirmModalCancel() {
			this.showConfirmModal = false;
		},
		onConfirmModalConfirm() {
			this.showConfirmModal = false;
			this.showRegisterModal = true;
		}
	}
}
</script>

<style scoped>
.manual-page-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: start;
	padding: 0 0.8em;
}


.manual-page-container {
	min-height: 100vh;
	position: relative;
	background-color: #191123;
	overflow-x: hidden;
	width: 100%;
}

.manual-page-important-info {
	color: #E6D5B8;
	font-size: 1.2em;
	text-align: left;
	padding: 0.2em 0.4em;
	margin-bottom: 1em;
	border-radius: 0.4em;
	background-color: rgba(230, 213, 184, 0.2);
}

.manual-page-time-require {
	color: #E6D5B8;
	font-size: 1.2em;
	text-align: left;
	padding: 0.2em 0.4em;
	margin-bottom: 1em;
	border-radius: 0.4em;
}

.manual-page-reference {
	color: #E6D5B8;
	font-size: 1.2em;
	text-align: left;
	padding: 0.2em 0.4em;
	margin-bottom: 1em;
	border-radius: 0.4em;
	word-break: keep-all;
	border: 1px solid #E6D5B8;
}

.manual-page-forbid {
	color: #e45826;
	font-size: 1.2em;
	text-align: left;
	padding: 0.2em 0.4em;
	margin-bottom: 1em;
	border-radius: 0.4em;
	background-color: rgba(228, 88, 38, 0.20);
}

.manual-page-reference-link {
	text-decoration: underline;
}

</style>
