<template>
	<div class='payment-method-modal-bg'>
		<div class='payment-method-modal'>
			<img src="../images/close.svg" alt="close" class="payment-method-modal-close-icon disable-select"
			     @click="onClosePaymentModal">
			<div class="payment-method-modal-title-container disable-select">
				请选择支付方式
			</div>
			<div class="payment-method-modal-content-container">
				<div v-if="getAvailablePaymentMethods.indexOf('alipay') >= 0"
				     class="payment-method-modal-content-row disable-select" @click="onMethodSelected('ALIPAY')">
					<img src="../images/alipay.svg" alt="alipay" class="payment-method-row-icon">
					<div class="payment-method-row-text">
						支付宝
					</div>
					<img v-if="this.currentSelected !== 'ALIPAY'" src="../images/radio_unselected.svg" alt="radio"
					     class="payment-method-row-selection-icon">
					<img v-if="this.currentSelected === 'ALIPAY'" src="../images/radio_selected.svg" alt="radio"
					     class="payment-method-row-selection-icon">
				</div>
				<div v-if="getAvailablePaymentMethods.indexOf('wechat_pay') >= 0"
				     class="payment-method-modal-content-row disable-select" @click="onMethodSelected('WECHATPAY')">
					<img src="../images/wechatpay.svg" alt="wechat_pay" class="payment-method-row-icon">
					<div class="payment-method-row-text">
						微信支付
					</div>

					<img v-if="this.currentSelected !== 'WECHATPAY'" src="../images/radio_unselected.svg" alt="radio"
					     class="payment-method-row-selection-icon">
					<img v-if="this.currentSelected === 'WECHATPAY'" src="../images/radio_selected.svg" alt="radio"
					     class="payment-method-row-selection-icon">
				</div>
				<div v-if="getAvailablePaymentMethods.indexOf('card') >= 0"
				     class="payment-method-modal-content-row disable-select" @click="onMethodSelected('CREDITCARD')">
					<img src="../images/credit_card.svg" alt="credit_card" class="payment-method-row-icon">
					<div class="payment-method-row-text">
						信用卡
					</div>
					<img v-if="this.currentSelected !== 'CREDITCARD'" src="../images/radio_unselected.svg" alt="radio"
					     class="payment-method-row-selection-icon">
					<img v-if="this.currentSelected === 'CREDITCARD'" src="../images/radio_selected.svg" alt="radio"
					     class="payment-method-row-selection-icon">
				</div>
				<div v-if="getAvailablePaymentMethods.indexOf('usdt') >= 0"
				     class="payment-method-modal-content-row disable-select" @click="onMethodSelected('USDT')">
					<img src="../images/USDT.svg" alt="alipay" class="payment-method-row-icon">
					<div class="payment-method-row-text">
						USDT
					</div>
					<img v-if="this.currentSelected !== 'USDT'" src="../images/radio_unselected.svg" alt="usdt"
					     class="payment-method-row-selection-icon">
					<img v-if="this.currentSelected === 'USDT'" src="../images/radio_selected.svg" alt="usdt"
					     class="payment-method-row-selection-icon">
				</div>
				<div class="payment-modal-btn-container">
					<a-button type="primary" class="my-sub-go-to-buy" size="large" @click="confirmPaymentMethod()">
						确定
					</a-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PaymentMethodsModal",
	data() {
		return {
			currentSelected: 'ALIPAY',
			productId: ''
		}
	},
	props: {
		availablePaymentMethods: {
			type: Array,
			required: true
		}
	},
	computed: {
		getAvailablePaymentMethods() {
			return this.availablePaymentMethods;
		}
	},
	// mounted() {
	// 	this.getAvailablePaymentMethods()
	// },
	methods: {
		onMethodSelected(selected) {
			this.currentSelected = selected
		},
		onClosePaymentModal() {
			this.$emit('closePaymentModal')
		},
		confirmPaymentMethod() {
			this.$emit('confirmPaymentMethod', this.currentSelected)
		}
	}
}
</script>

<style scoped>
.payment-method-modal-bg {
	position: fixed;
	width: 100vw;
	height: 100vh;
	backdrop-filter: blur(4px) opacity(100%) brightness(90%);
	z-index: 10000;
	background-color: rgba(0, 0, 0, 0.6);
	top: 0;
}

.payment-method-modal {
	position: fixed;
	background-color: rgb(0, 0, 0);
	transform: translate(-50%, 0%);
	left: 50%;
	width: 32em;
	top: 5em;
	border-radius: 1em;
	/*border: 1px solid rgba(255, 137, 0, 0.44);*/
	box-shadow: 0 0 8px 8px rgba(255, 137, 0, 0.15);
	padding: 1.6em 2.2em;

}

.payment-method-modal-title-container {
	width: 100%;
	text-align: center;
	color: #FF8900;
	font-size: 1.6em;
	padding-bottom: 1em;
}

.payment-method-modal-content-container {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.payment-method-modal-content-row {
	display: flex;
	flex-direction: row;
	color: #FF8900;
	font-size: 1.6em;
	padding: 0.4em 0;
	cursor: pointer;
}

.payment-method-row-icon {
	width: 1.2em;
	margin-right: 1em;
}

.payment-method-row-selection-icon {
	width: 1em;
}

.payment-method-row-text {
	flex: 1;
	text-align: start;
	font-size: 1.2em;
}

.payment-method-modal-close-icon {
	width: 1.4em;
	position: absolute;
	right: 1.6em;
	cursor: pointer;
}

@media only screen and (max-width: 900px) {
	.payment-method-modal {
		width: 24em;
	}
}

.payment-modal-btn-container{
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 1em;
}
</style>