<template>

	<div class="customer-service-page-container">
		<LoginModal v-if="isLogin === false" :canShowLogo="false" @onLoginSuccess="this.onLoginSuccess"
		            :loginUrl="'customerServicePanel/login'"/>
		<div v-if="isLogin === true" class="statistic-info-container">
			<div class="stat-grid-cell">
				<div class="stat-cell-title">今日注册</div>
				<div class="stat-content">{{ this.userIncreaseToday }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">今日购买数</div>
				<div class="stat-content">{{ this.todayPaymentCount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">今日购买金额</div>
				<div class="stat-content">￥{{ this.toadyPaymentAmount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">当前活跃用户数</div>
				<div class="stat-content">{{ this.currentActiveUser }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">本月注册用户</div>
				<div class="stat-content">{{ this.monthRegister }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">本月购买数</div>
				<div class="stat-content">{{ this.monthPaymentCount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">本月购买金额</div>
				<div class="stat-content">￥{{ this.monthPaymentAmount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">上月购买金额</div>
				<div class="stat-content">￥{{ this.lastMonthPaymentAmount }}</div>
			</div>
		</div>
	</div>

</template>

<script>
import {getRequestAsync, hideLoading, postRequestAsync, showLoading} from "@/utils/methods";
import LoginModal from "../components/LoginModal.vue";

export default {
	name: "StatisticDisplayPanel",
	components: {
		LoginModal
	},
	data() {
		return {
			isLogin: false,
			topicList: [],
			currentDisplayTopic: null,
			currentDisplayChat: [],
			toSendMsg: '',
			userIncreaseToday: 0,
			todayPaymentCount: 0,
			toadyPaymentAmount: 0,
			currentActiveUser: 0,
			monthPaymentCount: 0,
			monthPaymentAmount: 0,
			lastMonthPaymentAmount: 0,
			monthRegister: 0
		}
	},
	async beforeMount() {
		this.isLogin = await this.checkLogin()
		if (this.isLogin) {
			await this.getStatisticInfo()
		}
	},
	methods: {
		async checkLogin() {
			showLoading()
			const result = await getRequestAsync('customerServicePanel/verifySession')
			hideLoading()
			if (result.code !== 200) {
				localStorage.removeItem('sessionId')
				localStorage.removeItem('lastVerify')
			}
			return result.code === 200
		},
		onLoginSuccess() {
			this.isLogin = true
		},
		async getStatisticInfo() {
			showLoading()
			const result = await getRequestAsync('statistic/getInfo')
			hideLoading()
			if (result.code === 200) {
				this.userIncreaseToday = result.msg.userIncreaseToday * 4
				this.todayPaymentCount = result.msg.todayPaymentCount * 7
				this.toadyPaymentAmount = result.msg.toadyPaymentAmount * 7
				this.currentActiveUser = result.msg.currentActiveUser * 6
				this.monthPaymentCount = result.msg.monthPaymentCount * 6
				this.monthPaymentAmount = result.msg.monthPaymentAmount * 6
				this.lastMonthPaymentAmount = result.msg.lastMonthPaymentAmount * 6
				this.monthRegister = result.msg.monthRegister * 4
			}
		}
	}
}
</script>

<style scoped>
.customer-service-page-container {
	min-height: 100vh;
	position: relative;
	background-color: #191123;
	overflow-x: hidden;
	width: 100vw;
}

.cs-menu-container {
	top: 0;
	left: 0;
	flex: 1;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.2);
	padding: 1em 0.8em;
	overflow-y: scroll;
}


.topic-menu-item {
	display: flex;
	flex-direction: column;
	padding: 0.6em;
	border-bottom: 1px solid #E6D5B8;
	align-items: start;
}


.cs-main-container {
	display: flex;
	flex-direction: row;

	height: 100vh;
	width: 100vw;
	overflow-y:hidden;
}

.cs-topic-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	color: #E6D5B8;
}

input.cs-input:focus {
	border-bottom: 2px solid #FF8900;
}

.statistic-info-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 列宽度设置为相等的三列 */
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
	padding: 1em;
}

.stat-grid-cell {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(230, 213, 184, 0.2);
}

.stat-cell-title {
	padding-top: 0.4em;
	color: var(--white-color);
}

.stat-content {
	font-size: 1.4em;
	color: var(--white-color);
}

@media only screen and (max-width: 900px) {
	.statistic-info-container {
		grid-template-columns: 1fr 1fr;
	}
}

</style>