<template>
	<div class="statistic-info-general-container">
		<div class="statistic-refresh-btn-container" @click="this.onRefreshClicked">
			<img src="../../../images/refresh.svg" alt="refresh" class="statistic-refresh-btn-img">
			<div class="statistic-refresh-btn-text">刷新</div>
		</div>
		<div class="statistic-info-container">
			<div class="stat-grid-cell">
				<div class="stat-cell-title">今日注册</div>
				<div class="stat-content">{{ this.userIncreaseToday }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">今日购买数</div>
				<div class="stat-content">{{ this.todayPaymentCount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">今日购买金额</div>
				<div class="stat-content">￥{{ this.toadyPaymentAmount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">当前活跃用户数</div>
				<div class="stat-content">{{ this.currentActiveUser }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">本月注册用户</div>
				<div class="stat-content">{{ this.monthRegister }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">本月购买数</div>
				<div class="stat-content">{{ this.monthPaymentCount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">本月购买金额</div>
				<div class="stat-content">{{ this.monthPaymentAmount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">上月注册用户</div>
				<div class="stat-content">{{ this.lastMonthRegister }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">上月购买数</div>
				<div class="stat-content">{{ this.lastMonthPaymentCount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">上月购买金额</div>
				<div class="stat-content">{{ this.lastMonthPaymentAmount }}</div>
			</div>
		</div>
	</div>
</template>
<script>

import {getRequestAsync, hideLoading, postRequestAsync, showLoading} from "@/utils/methods";

export default {
	name: "GeneralStatisticView",
	components: {},
	data() {
		return {
			userIncreaseToday: 0,
			todayPaymentCount: 0,
			toadyPaymentAmount: 0,
			currentActiveUser: 0,
			monthPaymentCount: 0,
			lastMonthPaymentCount: 0,
			monthPaymentAmount: 0,
			lastMonthPaymentAmount: 0,
			monthRegister: 0,
			lastMonthRegister: 0,
			reverseIpList: []
		}
	},
	async beforeMount() {
		await this.getStatisticInfo()
		await this.getIpList()
	},
	methods: {
		async getStatisticInfo() {
			showLoading()
			const result = await getRequestAsync('statistic/getInfo')
			hideLoading()
			if (result?.code === 200) {
				this.userIncreaseToday = result.msg.userIncreaseToday
				this.todayPaymentCount = result.msg.todayPaymentCount
				this.toadyPaymentAmount = result.msg.toadyPaymentAmount
				this.currentActiveUser = result.msg.currentActiveUser
				this.monthPaymentCount = result.msg.monthPaymentCount
				this.lastMonthPaymentCount = result.msg.lastMonthPaymentCount
				this.monthPaymentAmount = result.msg.monthPaymentAmount
				this.lastMonthPaymentAmount = result.msg.lastMonthPaymentAmount
				this.monthRegister = result.msg.monthRegister
				this.lastMonthRegister = result.msg.lastMonthRegister
			}
		},
		async onRefreshClicked() {
			await this.getStatisticInfo()
		},
		async getIpList() {

			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				},
				mode: 'cors' // 确保使用 CORS 模式
			};

			const response = await fetch('https://ip-list-web.nebulabinaryspace.work/list.json', requestOptions);

			// 打印响应对象
			console.log('Response:', response);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const formatted = (await response.text()).replace(/\s+/g, "")

			console.log(formatted)

			const data = JSON.parse(formatted)
			console.log('Fetched JSON data:', data.data);

			const reverseIpList = data.data.map(base64String => {
				return atob(base64String)
			});

			this.reverseIpList = []

			for (const item of reverseIpList) {
				this.reverseIpList.push({
					url: item,
					connect: false
				})
			}
			console.log(this.reverseIpList)
			await this.testUrls()
		},
		async testUrls() {
			for (const item of this.reverseIpList) {
				const response = await postRequestAsync(item.url + '/connect/test', {key: 'ad2992c4-00e0-4668-ab4c-375b0f45a431'}, true)

				if (response?.code === 200) {
					item.connect = true
				} else {
					item.connect = false
				}
			}

		}
	}
}
</script>

<style scoped>

.statistic-refresh-btn-container {
	padding-bottom: 0.6em;
}

.statistic-info-general-container {
	width: 100%;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: start;
	color: #E6D5B8;
}

input.cs-input:focus {
	border-bottom: 2px solid #FF8900;
}

.statistic-info-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 列宽度设置为相等的三列 */
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
	width: 100%;
	box-sizing: border-box;
}

.stat-grid-cell {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(230, 213, 184, 0.2);
}

.stat-cell-title {
	padding-top: 0.4em;
	color: var(--white-color);
}

.stat-content {
	font-size: 1.4em;
	color: var(--white-color);
}

@media only screen and (max-width: 900px) {
	.statistic-info-container {
		grid-template-columns: 1fr 1fr;
	}
}


</style>