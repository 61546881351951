<template>
	<div class="login-page-main-container">
		<transition name="fade">
			<login-modal v-if="this.currentDisplay==='LOGIN'" @onNewUser="this.onRegisterNewUser" @onForgotPassword="this.onForgotPassword" @onLoginSuccess="this.onLoginSuccess"></login-modal>
		</transition>
		<transition name="fade">
			<register-modal v-if="this.currentDisplay==='REGISTER'" @goLogin="this.goLogin"></register-modal>
		</transition>
		<transition name="fade">
			<reset-password-modal v-if="this.currentDisplay==='RESETPASSWORD'" @onLogin="this.goLogin"></reset-password-modal>
		</transition>

		<NotificationModal v-if="showNotificationModal === true" @ok="onNotificationOk" :message=this.notificationMessage />
	</div>
</template>

<script>

import LoginModal from '../components/LoginModal.vue'
import RegisterModal from '../components/RegisterModal.vue'
import ResetPasswordModal from '../components/ResetPassword.vue'
import NotificationModal from "../components/NotificationModal.vue";
import {reportVisit} from "@/utils/methods";

export default {
	name: "LoginPage",
	components: {
		LoginModal,
		RegisterModal,
		ResetPasswordModal,
		NotificationModal
	},
	data() {
		return {
			currentDisplay: 'LOGIN',
			showNotificationModal: false,
			notificationMessage: '亲爱的用户您好，\n我们的服务器近期遭到了网络攻击，造成了数据丢失。如果您的会员信息丢失，请重新注册后将最后一次购买的截图发送至：george.gu@nebulabinary.com这个邮箱，客服这边会帮您处理。\n感谢您的理解和支持。\n\n星云团队'

		}
	},
	async mounted() {
		await reportVisit('web_visit_login')
	},
	methods: {
		onNotificationOk() {
			this.showNotificationModal = false
		},
		onRegisterNewUser() {
			this.currentDisplay = 'REGISTER'
		},
		goLogin() {
			this.currentDisplay = 'LOGIN'
		},
		onForgotPassword() {
			this.currentDisplay = 'RESETPASSWORD'
		},
		onLoginSuccess() {
			this.$router.push('/dashboard')
		}
	}
}
</script>

<style scoped>
.login-page-main-container {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-image: url('../images/matrix_bg.jpg');
	top: 0;
	left: 0;
}

</style>