<template>
	<div class="sub-view-container">

		<Teleport to="body">
			<transition name="fade">
				<PaymentMethodsModal v-if="showPaymentMethodsModal" @closePaymentModal="onClosePaymentModal" @confirmPaymentMethod="onConfirmPaymentMethod" :availablePaymentMethods="availablePaymentMethods"></PaymentMethodsModal>
			</transition>
		</Teleport>

		<sub-view-header :title="'订阅套餐'"/>

		<div class="sub-view-content">
			<div class="plan-card-container">
				<template v-for="item in this.planList" :key="item.code">

					<div class="plan-card-wrapper" v-if="item.display !== false">
						<div class="plan-card-title">
							<img v-if="item.isDiamond" src="../../images/diamond.svg" alt="diamond" style="width: 1em;"/>
							{{ item.title }}
						</div>
						<div v-if="!item?.promoPrice" class="plan-card-price">￥{{ (item.price / 100).toFixed() }} 元</div>
						<div v-if="item?.promoPrice" class="plan-card-non-promo-price">原价￥{{ (item?.price / 100).toFixed() }} 元</div>
						<div v-if="item?.promoPrice" class="plan-card-promo-price">特惠价￥{{ (item?.promoPrice / 100).toFixed() }} 元</div>
						<div class="plan-card-bandwidth">流量：{{ item.bandWidth }}GB/30天</div>
						<div class="plan-card-bandwidth">有效期：{{ item.validDays }}天</div>
						<div class="plan-card-device-count">设备数：无限制</div>
						<div class="plan-card-node-count">节点数量：全部</div>
						<div class="plan-card-unlock-nf">流媒体解锁、chatgpt优化</div>
						<div class="plan-card-btn-wrapper">
							<a-button type="primary" class="my-sub-go-to-buy" size="middle" @click="buyPlan(item.code)">
								购买
							</a-button>
						</div>

					</div>
				</template>

			</div>
		</div>
<!--		<div class="plan-notification">支持24小时内退款，退款需承担15%手续费。</div>-->
	</div>
</template>

<script>
import SubViewHeader from "../../components/SubViewHeader.vue";
import PaymentMethodsModal from "../../components/PaymentMethodsModal.vue";
import {postRequest, showLoading, hideLoading, getSubscriptionPlans} from '@/utils/methods';
import {localValue} from '@/utils/constants';

export default {
	name: "PlanPage",
	components: {
		SubViewHeader,
		PaymentMethodsModal
	},
	data() {
		return {
			planList: [],
			showPaymentMethodsModal: false,
			selectedProductId: '',
			availablePaymentMethods: []
		}
	},
	async mounted() {
		const sessionId = localStorage.getItem('sessionId');
		if (!sessionId) {
			localStorage.removeItem('sessionId');
			localStorage.removeItem('lastVerify')
			this.$router.push('/login');
			return
		}
		const planLastUpdate = parseInt(localValue.subPlanUpdateTimestamp)
		if (planLastUpdate > 0) {
			const currentTimestamp = parseInt(new Date().getTime() / 1000);
			if (currentTimestamp - planLastUpdate < 60 * 30) {
				this.planList = localValue.subPlans
				return
			}
		}
		const {err, result} = await getSubscriptionPlans()
		if (err === false && result?.code === 200) {
			this.planList = result.msg.subPlans
			this.planList.sort((a, b)=> {
				if ((a.isDiamond && b.isDiamond) || (!a.isDiamond && !b.isDiamond)) {
					return a.validDays - b.validDays
				}
				if (a.isDiamond) {
					return -1
				}
				return 1
			})
			localValue.subPlans = this.planList
			localValue.subPlanUpdateTimestamp = parseInt(new Date().getTime() / 1000)
		}

	},
	methods: {
		buyPlan(value) {
			this.availablePaymentMethods = []
			if (value) {
				postRequest('airport/payment/getAvailablePaymentMethods', {productId: value}, (result, data) => {
					if (result !== 'success') {
						this.$message.error('获取支付方式失败');
						return
					}

					if(data.code === 200) {
						this.availablePaymentMethods = data.msg.paymentMethods
					}
				})

				this.selectedProductId = value
				this.showPaymentMethodsModal = true
			}

		},
		onClosePaymentModal() {
			this.showPaymentMethodsModal = false
		},
		onConfirmPaymentMethod(paymentMethod) {
			if (paymentMethod === 'USDT') {
				postRequest('airport/payment/createTransaction', {planType: this.selectedProductId}, (result, data) => {

					if (result !== 'success') {
						this.$message.error('获取支付链接失败');
						return
					}

					if(data.code === 200 && data.msg.transactionId) {
						// console.log(`payment url: ${data.msg.paymentUrl}`)
						// window.open(data.msg.paymentUrl, '_blank');
						this.$router.push(`/usdtPayment?id=${data.msg.transactionId}`);

					}
				})
				return
			}

			if (paymentMethod === 'CREDITCARD') {

				const windowReference = window.open('', '_blank');
				postRequest('airport/payment/getPaymentUrl', {planType: this.selectedProductId}, (result, data) => {
					if (result !== 'success') {
						this.$message.error('获取支付链接失败');
						return
					}

					if(data.code === 200 && data.msg.paymentUrl) {
						windowReference.location = data.msg.paymentUrl;
					}
				})
				return
			}
			let postPaymentMethod
			if (paymentMethod === 'ALIPAY') {
				postPaymentMethod = 'alipay'
			} else if (paymentMethod === 'WECHATPAY') {
				postPaymentMethod = 'wechatpay'
			} else {
				this.$message.error('请选择支付方式');
				return
			}
			const windowReference = window.open('', '_blank');
			postRequest('airport/payment/createPayment', {paymentMethod: postPaymentMethod, planType: this.selectedProductId}, (result, data) => {

				if (result !== 'success') {
					this.$message.error('获取支付链接失败');
					return
				}

				if(data.code === 200 && data.msg.paymentUrl) {
					windowReference.location = data.msg.paymentUrl;

				}
			})
		}
	}
}
</script>

<style scoped>
.plan-card-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 列宽度设置为相等的三列 */
	gap: 1em; /* 网格间距 */
	justify-content: start;
	align-items: start;
}

.plan-card-wrapper {
	display: flex;
	flex-direction: column;
	align-items: start;
	border: 1px solid #FF8900;
	border-radius: 0.3em;
	padding: 1em 2em;
	height: 100%;
	justify-content: center;
}

.plan-card-title {
	color: #FF8900;
	width: 100%;
	text-align: center;
	font-size: 1.6em;
}

.plan-card-price {
	color: #FF8900;
	font-size: 1.6em;
	width: 100%;
	text-align: center;
}

.plan-card-non-promo-price{
	color: #FF8900;
	font-size: 1.4em;
	width: 100%;
	text-align: center;
	text-decoration: line-through;
}

.plan-card-promo-price {
	color: #E45826;
	font-size: 1.6em;
	width: 100%;
	text-align: center;
}

.plan-card-bandwidth {
	color: #E6D5B8;
	font-size: 1.2em;
	padding: 0.3em 0;
}

.plan-card-device-count {
	color: #E6D5B8;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 1.2em;
}

.plan-card-node-count {
	color: #E6D5B8;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 1.2em;
	padding: 0.3em 0;

}

.plan-card-unlock-nf {
	color: #E6D5B8;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 1.2em;
	padding: 0.3em 0;

}

.plan-card-btn-wrapper {
	width: 100%;
	align-items: center;
	margin-top: 1em;
}

.plan-notification {
	color: #E6D5B8;
	padding: 1em 0;
	font-size: 1.1em;
}

@media only screen and (max-width: 900px) {
	.plan-card-container{
		display: grid !important;
		grid-template-columns: repeat(2, 1fr) !important;
		margin-bottom: 3em;
		padding: 0 0.8em;
		width: 100%;
	}

	.plan-card-wrapper{
		padding: 0.8em !important;
	}

	.plan-card-title{
		font-size: 1.2em !important;
	}
	.plan-card-price{
		font-size: 1.4em;
	}
}
</style>