import Vuex from "vuex";

export default new Vuex.Store({
	state: {
		vcodeCountdown: -1
	},
	mutations: {
		SET_VCODE_COUNTDOWN(state, payload) {
			state.vcodeCountdown = payload;
		},
		VCODE_COUNTDOWN(state) {
			if (state.vcodeCountdown >= 0) {
				state.vcodeCountdown--
			}
		}
	},
	actions: {
		setVcodeCountdown({ commit }, payload) {
			commit('SET_VCODE_COUNTDOWN', payload);
		},
		vcodeCountdown({ commit }, payload) {
			commit('VCODE_COUNTDOWN', payload);
		}
	},
});