<template>
	<div class="statistic-visit-container">
		<div class="statistic-refresh-btn-container" @click="this.onRefreshClicked">
			<img src="../../../images/refresh.svg" alt="refresh" class="statistic-refresh-btn-img">
			<div class="statistic-refresh-btn-text">刷新</div>
		</div>
		<div class="visit-statistic-label">本月新增用户数：{{ this.addThisMonthCount }}</div>
		<div class="visit-statistic-label">本月流失用户数：{{ this.lostThisMonthCount }}</div>
	</div>
</template>
<script>
import {getRequestAsync, hideLoading, showLoading, visitClassifyByUrl} from "@/utils/methods";

export default {
	name: "UserStatisticView",
	components: {},
	data() {
		return {
			addThisMonthCount: 0,
			lostThisMonthCount: 0
		}

	},
	async beforeMount() {
		await this.getUserStatisticInfo()
	},
	methods: {
		async getUserStatisticInfo () {
			showLoading()
			const result = await getRequestAsync('statistic/getUserStatisticInfo')
			hideLoading()
			if (result?.code === 200) {
				this.addThisMonthCount = result.msg?.addThisMonthCount
				this.lostThisMonthCount = result.msg?.lostThisMonthCount
			}
		},
		async onRefreshClicked() {
			await this.getUserStatisticInfo()
		}
	}
}
</script>

<style scoped>
.statistic-refresh-btn-container{
	padding-bottom: 0.6em;
}
.visit-statistic-label{
	color: #FF8900;
}

</style>