<template>
	<div class="sub-view-container">
		<sub-view-header :title="'使用指南'"/>
		<div class="manual-page-container">
			<a-collapse v-model:activeKey="activeKey" style="width: 100%;">
				<template #expandIcon="{ isActive }">
					<caret-right-outlined :rotate="isActive ? 90 : 0"/>
				</template>
<!--				<a-collapse-panel key="1" header="苹果手机使用指南" :style="customStyle"-->
				<a-collapse-panel key="1" :style="customStyle"
				                  :expand-icon-position="expandIconPosition">

					<div class="manual-page-panel-content-container">
						<img src="../../images/manual/ios.jpg" alt="mac manual" style="width: 100%;"/>
					</div>
					<template #header><div class="manual-collapse-header"><span>苹果手机使用指南</span></div></template>
					<template #extra>
						<img src="../../images/share.svg" @click="downloadManual('IOS', $event)" alt="download"
						     style="width:1em;margin-right:0.4em;"/>
						<span style="font-size: 0.8em;color: #E6D5B8;"
						      @click="downloadManual('IOS', $event)">在新页面打开</span>
					</template>
				</a-collapse-panel>
				<a-collapse-panel key="2" header="Mac使用指南" :style="customStyle">
					<div class="manual-page-panel-content-container">
						<img src="../../images/manual/mac.jpg" alt="mac manual" style="width: 100%;"/>
					</div>
					<template #extra>
						<img src="../../images/share.svg" @click="downloadManual('MAC', $event)"
						     alt="download" style="width:1em;margin-right:0.4em;"/>

						<span style="font-size: 0.8em;color: #E6D5B8;"
						      @click="downloadManual('MAC', $event)">在新页面打开</span>
					</template>
				</a-collapse-panel>
				<a-collapse-panel key="3" header="Windows使用指南" :style="customStyle">

					<div class="manual-page-panel-content-container">
						<img src="../../images/manual/win.jpg" alt="win manual" style="width: 100%;"/>
					</div>
					<template #extra>
						<img src="../../images/share.svg" @click="downloadManual('WIN', $event)"
						     alt="download" style="width:1em;margin-right:0.4em;"/>

						<span style="font-size: 0.8em;color: #E6D5B8;"
						      @click="downloadManual('WIN', $event)">在新页面打开</span>
					</template>
				</a-collapse-panel>
				<a-collapse-panel key="4" header="安卓使用指南" :style="customStyle">
					<div class="manual-page-panel-content-container">
						<img src="../../images/manual/and.jpg" alt="win manual" style="width: 100%;"/>
					</div>
					<template #extra>
						<img src="../../images/share.svg" @click="downloadManual('ANDROID', $event)"
						     alt="download" style="width:1em;margin-right:0.4em;"/>

						<span style="font-size: 0.8em;color: #E6D5B8;"
						      @click="downloadManual('ANDROID', $event)">在新页面打开</span>
					</template>
				</a-collapse-panel>
			</a-collapse>
		</div>

	</div>
</template>

<script>
import SubViewHeader from "../../components/SubViewHeader.vue";
import {CaretRightOutlined} from '@ant-design/icons-vue';

export default {
	name: "ManualPage",
	components: {
		SubViewHeader,
		CaretRightOutlined
	},
	methods: {
		downloadManual(type, event) {
			console.log(type);
			event.stopPropagation();
			if (type === 'WIN') {
				setTimeout(() => {
					window.open('/files/manual_win.pdf', '_blank');
				})
				return
			}

			if (type === 'MAC') {
				setTimeout(() => {
					window.open('/files/manual_mac.pdf', '_blank');
				})
				return
			}

			if (type === 'ANDROID') {
				setTimeout(() => {
					window.open('/files/manual_and.pdf', '_blank');
				})
				return
			}

			if (type === 'IOS') {
				setTimeout(() => {
					window.open('/files/manual_ios.pdf', '_blank');
				})

			}

		}
	}
}
</script>

<style scoped>
.manual-page-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: start;
	padding: 0 3em;
}

.manual-page-panel-content-container {

}

.manual-collapse-header{
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 900px) {
	.manual-page-container {
		padding: 0 0.8em !important;
	}
}


</style>