<template>
	<!--	<div class="sl-confirm-modal-bg-container"event.stopPropagation()">-->
	<div class='sl-confirm-modal-bg' @click="onBgClick($event)">
		<div class='sl-confirm-modal'>
			<div class='sl-confirm-modal-container'>
				<div class='sl-confirm-modal-title'>{{ this.title }}</div>
				<div class='sl-confirm-modal-content'>{{ this.message }}</div>

				<div class='sl-confirm-modal-btn-container'>
					<a-button type="primary" size="middle" @click="onOK" style="color: #FF8900;background-color: #191123FF;margin-right: 0.5em;">
						确定
					</a-button>
				</div>
			</div>
		</div>
	</div>
	<!--	</div>-->


</template>

<script>
import {postRequest, verifyEmail} from '@/utils/methods'

export default {
	name: "NotificationModal",
	data() {
		return {
			email: '',
			vcode: '',
			password: '',
			confirmPassword: '',
			sendEmailCountDown: -1,
			countDownInterval: null
		}
	},
	props: {
		message: String,
		title:{
			type: String,
			required: false,
			default: '系统消息'
		}
	},
	methods: {
		onOK() {
			this.$emit('ok')
		},
		onBgClick(event) {
			event.stopPropagation()
		}
	}
}
</script>

<style scoped>
input {
	color: #FF8900;
}


@media only screen and (max-width: 900px) {
	.sl-confirm-modal {

		width: 90% !important;
	}
}

.sl-confirm-modal {
	width: 40em;
	position: fixed;
	background-color: #191123;
	transform: translate(-50%, 0%);
	left: 50%;
	top: 5em;
	border-radius: 0.4em;
	/* border: 1px solid rgba(255, 137, 0, 0.44); */
	box-shadow: 0 0 2px 2px rgba(134, 76, 8, 0.15);
	padding: 0.8em;
	backdrop-filter: blur(6px);
}

.swiper-slide-active > .sl-avatar-name {
	text-shadow: 2px 2px 5px red;
}

.sl-confirm-modal-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.sl-confirm-modal-content{
	font-size: 1.2em;
	width: 100%;
	text-align: left;
	color: #E6D5B8;
	margin-bottom: 0.8em;
	user-select: text;
	white-space: pre-wrap;
	overflow-wrap: break-word;
}

.sl-confirm-modal-title {
	font-size: 1.2em;
	width: 100%;
	text-align: center;
	color: #E6D5B8;
	margin-bottom: 0.8em;
	user-select: text;
}

.sl-confirm-send-vcode-btn {
	background-color: #ff8900;
	font-size: 1em;
	color: #3d3020;
	border-radius: 0.2em;
	padding: 0 0.3em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: fit-content;
	word-break: keep-all;
}

.sl-confirm-send-vcode-btn:active {
	transform: scale(0.9);
}

.sl-confirm-input-email {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.2em;
	background: transparent;
	margin-top: 0.4em;
	flex: 1 1;
}

input.sl-confirm-input:focus {
	border-bottom: 2px solid #FF8900;
}

input.sl-confirm-input-email:focus {
	border-bottom: 2px solid #FF8900;
}

.sl-confirm-email-field-container {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.sl-confirm-modal-bg {
	position: fixed;
	width: 100vw;
	/* backdrop-filter: blur(8px) opacity(100%) brightness(90%); */
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.2);
	top: 0;
	left: 0;
	height: 100vh;
	backdrop-filter: blur(3px);
}

.sl-confirm-modal-close-container {
	width: 100%;
	text-align: right;
}

.sl-confirm-modal-bg-container{
	width: 100vw;
	height: 100vh;
}
.sl-confirm-modal-btn-container{
	text-align: right;
	width: 100%;
}
</style>