<template>
	<div class="reference-page-container">
		<Teleport to="body">
				<WithdrawConfirmModal v-if="this.showWithdrawConfirmModal"
				                      @cancel="this.onCloseWithdrawConfirmModal"
				:email="this.email"/>
		</Teleport>

		<LoginModal v-if="isLogin === false" :canShowLogo="false" @onLoginSuccess="this.onLoginSuccess"></LoginModal>
		<div v-if="isLogin === true" style="padding: 1em;">
			<a-tabs v-model:activeKey="activeKey" @change="onTabChanged">
				<a-tab-pane key="0" tab="概览" v-if="this.showOverviewPage">
					<div class="table-tool-bar">
						<div class="table-toolbar-btn" @click="refreshPage('overview')">
							<img src="../images/refresh.svg" alt="refresh" class="table-toolbar-img">
							<div class="table-toolbar-btn-text">
								刷新
							</div>
						</div>
					</div>
					<div class="overview-tab-content-container">
						<div class="overview-grid-cell">
							<div class="overview-cell-title">今日分享数</div>
							<div class="overview-content">{{ this.overviewData.todayReferenceCount }}</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">
								昨日分享数
							</div>
							<div class="overview-content">
								{{ this.overviewData.yesterdayReferenceCount }}
							</div>

						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">
								总分享数
							</div>
							<div class="overview-content">
								{{ this.overviewData.totalReferenceCount }}
							</div>

						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">今日安装数</div>
							<div class="overview-content">{{ this.overviewData.todayInstallCount }}</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">
								昨日安装数
							</div>
							<div class="overview-content">
								{{ this.overviewData.yesterdayInstallCount }}
							</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">
								总安装数
							</div>
							<div class="overview-content">
								{{ this.overviewData.totalInstallCount }}
							</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">今日注册/安装率</div>
							<div class="overview-content">{{ this.overviewData.todayRegRate }}%</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">
								昨日注册/安装率
							</div>
							<div class="overview-content">
								{{ this.overviewData.yesterdayRegRate }}%
							</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">
								总注册/安装率
							</div>
							<div class="overview-content">
								{{ this.overviewData.totalRegRate }}%
							</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">今日购买次数</div>
							<div class="overview-content">{{ this.overviewData.todayDividendCount }}</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">
								昨日购买次数
							</div>
							<div class="overview-content">
								{{ this.overviewData.yesterdayDividendCount }}
							</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">
								总购买次数
							</div>
							<div class="overview-content">
								{{ this.overviewData.totalDividendCount }}
							</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">今日购买/注册率</div>
							<div class="overview-content">{{ this.overviewData.todayPurchaseRate }}%</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">
								昨日购买/注册率
							</div>
							<div class="overview-content">
								{{ this.overviewData.yesterdayPurchaseRate }}%
							</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">
								总购买/注册率
							</div>
							<div class="overview-content">
								{{ this.overviewData.totalPurchaseRate }}%
							</div>
						</div>
					</div>

				</a-tab-pane>
				<a-tab-pane key="1" tab="分享统计">
					<div class="table-tool-bar">
						<div class="table-toolbar-btn" @click="refreshPage('reference')">
							<img src="../images/refresh.svg" alt="refresh" class="table-toolbar-img">
							<div class="table-toolbar-btn-text">
								刷新
							</div>
						</div>
					</div>
					<div class="overview-container">
						<div class="overview-grid-cell">
							<div class="overview-cell-title">今日分享数</div>
							<div class="overview-content">{{ this.referenceTCount }}</div>
						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">
								昨日分享数
							</div>
							<div class="overview-content">
								{{ this.referenceYCount }}
							</div>

						</div>
						<div class="overview-grid-cell">
							<div class="overview-cell-title">
								总分享数
							</div>
							<div class="overview-content">
								{{ this.totalCount }}
							</div>

						</div>
					</div>
					<a-pagination v-model:current="this.pageCount" v-model:pageSize="this.pageSize"
					              :total="this.totalCount" @change="onPageChanged" style="padding: 0.8em 0;"
					              :showSizeChanger="false"/>
					<div class="reference-table-container">
						<div class="reference-table-row">
							<div class="reference-table-cell">邮箱</div>
							<div class="reference-table-cell">注册时间</div>
						</div>
						<template v-for="item in this.referenceRecordList" :key="item._id">

							<div class="reference-table-row">
								<div class="reference-table-cell">{{ item.referToEmail }}</div>
								<div class="reference-table-cell">{{ item.createTimeString }}</div>
							</div>
						</template>
					</div>
				</a-tab-pane>
				<a-tab-pane key="2" tab="安装统计" v-if="this.showInstallState">

					<div v-if="this.installList.length === 0">
						<div class="dividend-empty-container">
							<img src="../images/empty.svg" alt="empty"
							     style="width: 8em; margin-bottom: 0.4em; opacity: 0.4;">
							<div style="color: #E6D5B8; opacity: 0.4;">暂无安装记录</div>
						</div>
					</div>
					<div v-if="this.installList.length > 0">

						<div class="table-tool-bar">
							<div class="table-toolbar-btn" @click="refreshPage('install')">
								<img src="../images/refresh.svg" alt="refresh" class="table-toolbar-img">
								<div class="table-toolbar-btn-text">
									刷新
								</div>
							</div>
						</div>

						<div class="overview-container">
							<div class="overview-grid-cell">
								<div class="overview-cell-title">今日安装数</div>
								<div class="overview-content">{{ this.installTCount }}</div>
							</div>
							<div class="overview-grid-cell">
								<div class="overview-cell-title">
									昨日安装数
								</div>
								<div class="overview-content">
									{{ this.installYCount }}
								</div>

							</div>
							<div class="overview-grid-cell">
								<div class="overview-cell-title">
									安装总数
								</div>
								<div class="overview-content">
									{{ this.installTotalCount }}
								</div>

							</div>
							<div class="overview-grid-cell">
								<div class="overview-cell-title">今日注册/安装率</div>
								<div class="overview-content">{{ this.tInstallRate }}</div>
							</div>
							<div class="overview-grid-cell">
								<div class="overview-cell-title">
									昨日注册/安装率
								</div>
								<div class="overview-content">
									{{ this.yInstallRate }}
								</div>

							</div>
							<div class="overview-grid-cell">
								<div class="overview-cell-title">
									总注册/安装率
								</div>
								<div class="overview-content">
									{{ this.totalInstallRate }}
								</div>

							</div>
						</div>
						<a-pagination v-model:current="this.installPageCount" v-model:pageSize="this.pageSize"
						              :showSizeChanger="false"
						              :total="this.installTotalCount" @change="onInstallPageChanged"
						              style="padding: 0.8em 0;"/>
						<div class="reference-table-container">
							<div class="reference-table-row">
								<div class="reference-table-cell">安装时间</div>
								<div class="reference-table-cell">注册账户</div>
							</div>
							<template v-for="item in this.installList" :key="item._id">

								<div class="reference-table-row">
									<div class="reference-table-cell">{{ item.createTimeString }}</div>
									<div class="reference-table-cell">{{ item.email || '无信息' }}</div>
								</div>
							</template>
						</div>
					</div>

				</a-tab-pane>
				<a-tab-pane key="3" tab="分红列表" force-render>
					<div v-if="this.dividendList.length === 0">
						<div class="dividend-empty-container">
							<img src="../images/empty.svg" alt="empty"
							     style="width: 8em; margin-bottom: 0.4em; opacity: 0.4;">
							<div style="color: #E6D5B8; opacity: 0.4;">暂无分红记录</div>
						</div>
					</div>
					<div v-if="this.dividendList.length > 0" class="dividend-tab-container">
						<div class="table-tool-bar">
							<div class="table-toolbar-btn" @click="refreshPage('dividend')">
								<img src="../images/refresh.svg" alt="refresh" class="table-toolbar-img">
								<div class="table-toolbar-btn-text">
									刷新
								</div>
							</div>
						</div>
						<div class="overview-container">
							<div class="overview-grid-cell">
								<div class="overview-cell-title">今日分红笔数</div>
								<div class="overview-content">{{ this.todayDividendCount }}</div>
							</div>
							<div class="overview-grid-cell">
								<div class="overview-cell-title">
									昨日分红笔数
								</div>
								<div class="overview-content">
									{{ this.yesterdayDividendCount }}
								</div>

							</div>
							<div class="overview-grid-cell">
								<div class="overview-cell-title">
									分红总笔数
								</div>
								<div class="overview-content">
									{{ this.totalDividendCount }}
								</div>

							</div>
							<div class="overview-grid-cell">
								<div class="overview-cell-title">
									今日分红金额 (USDT)
								</div>
								<div class="overview-content">
									{{ this.todayDividendAmount }}
								</div>

							</div>
							<div class="overview-grid-cell">
								<div class="overview-cell-title">
									昨日分红金额 (USDT)
								</div>
								<div class="overview-content">
									{{ this.yesterdayDividendAmount }}
								</div>

							</div>
							<div class="overview-grid-cell">
								<div class="overview-cell-title">
									分红总金额 (USDT)
								</div>
								<div class="overview-content">

									{{ this.totalDividendAmount }}
								</div>
							</div>
						</div>

						<a-pagination v-model:current="this.pageCount" v-model:pageSize="this.pageSize"
						              :total="this.totalDividendCount" @change="onDividendPageChanged"
						              style="padding: 0.8em 0;"
						              :showSizeChanger="false"/>
						<template v-for="item in this.dividendList" :key="item.recordId">

							<div class="dividend-record-container">
								<div class="dividend-record-title">{{ item.buyerUserEmail }}</div>
								<div class="dividend-record-line">注册时间：{{ item.registerTime }}</div>
								<div class="dividend-record-line">购买时间：{{ item.timeString }}</div>
								<div class="dividend-record-line">奖励时长：{{
										this.getDurationDisplay(item.receiveDuration)
									}}
								</div>
								<div class="dividend-record-line">奖励USDT：{{
										this.getUsdtDisplay(item.receiveUSDT)
									}}
								</div>
								<div class="dividend-record-line is-received" v-if="item.isReceived === true">已领取
								</div>
								<div class="dividend-record-line is-not-received" v-if="item.isReceived !== true">未领取
								</div>
							</div>
						</template>
						<!--						<div class="dividend-btn-container">-->
						<!--							<a-button type="primary" size="middle" @click="withdrawAll" class="withdraw-dividend-btn">-->
						<!--								全部领取-->
						<!--							</a-button>-->
						<!--						</div>-->
					</div>
				</a-tab-pane>
				<a-tab-pane key="4" tab="账户信息">
					<div class="account-info-container">
						<div class="account-info-line">邮箱：{{ this.email }}</div>
						<div class="account-info-line">可提现余额：{{ this.withdrawableDividend }} USDT</div>
						<div class="withdraw-dividend-btn" @click="this.onWithdrawClicked">提现</div>
						<div class="dividend-wallet-container">
							<div class="dividend-wallet-title"><img src="../images/wallet.svg" alt="wallet"
							                                        style="width: 1.2em; margin-right: 0.4em;">
								<div style="flex: 1;text-align: start;">分红钱包地址</div>
								<div @click="onEditWallet"><img src="../images/edit.svg" alt="edit"
								                                style="width: 1.2em;margin-right: 0.2em;">编辑
								</div>
							</div>
							<div class="dividend-wallet-content-line">
								<img src="../images/crypto/trx.svg" alt="trc20"
								     style="width: 1.2em; margin-right: 0.4em">
								<div style="flex: 1; text-align: start;">trc20:</div>
								{{ this.trc20WalletAddress || '未设置' }}
							</div>
							<div class="dividend-wallet-content-line"><img src="../images/crypto/bsc.svg" alt="trc20"
							                                               style="width: 1.2em; margin-right: 0.4em">
								<div style="flex: 1; text-align: start;">bep20:</div>
								{{ this.bep20WalletAddress || '未设置' }}
							</div>

						</div>
					</div>
				</a-tab-pane>
				<!--				<a-tab-pane key="5" tab="提现记录">-->
				<!--				</a-tab-pane>-->
			</a-tabs>

			<EditWalletModal v-if="this.showEditWalletModal === true" :userEmail="this.email"
			                 @onEditCancel="onEditWalletCancel" @onEditClose="onEditWalletClose"></EditWalletModal>
		</div>
	</div>
</template>

<script>
import LoginModal from "../components/LoginModal.vue";
import EditWalletModal from "../components/EditWalletModal.vue";
import {postRequest, roundNumber, getDividendInfo, withdrawDividend} from "@/utils/methods";
// import PaymentMethodsModal from "@/components/PaymentMethodsModal.vue";
import WithdrawConfirmModal from "@/components/WithdrawConfirmModal.vue";

export default {
	name: "ReferencePage",
	components: {
		WithdrawConfirmModal,
		LoginModal,
		EditWalletModal
	},
	data() {
		return {
			isLogin: false,
			referenceRecordList: [],
			activeKey: '1',
			pageCount: 1,
			dividendPageCount: 1,
			dividendTotalCount: 0,
			totalCount: 0,
			pageSize: 20,
			dividendList: [],
			email: '',
			trc20WalletAddress: '',
			bep20WalletAddress: '',
			showEditWalletModal: false,
			installList: [],
			installPageCount: 1,
			installTotalCount: 0,
			showInstallState: false,
			showOverviewPage: false,
			installYCount: 0,
			installTCount: 0,
			referenceYCount: 0,
			referenceTCount: 0,
			totalDividendCount: 0,
			totalDividendAmount: 0,
			yesterdayDividendCount: 0,
			yesterdayDividendAmount: 0,
			todayDividendCount: 0,
			todayDividendAmount: 0,
			tInstallRate: 0,
			yInstallRate: 0,
			totalInstallRate: 0,
			withdrawableDividend: 0,
			overviewData: {
				totalReferenceCount: 0,
				todayReferenceCount: 0,
				yesterdayReferenceCount: 0,
				totalRegRate: 0,
				todayRegRate: 0,
				yesterdayRegRate: 0,
				totalDividendCount: 0,
				todayDividendCount: 0,
				yesterdayDividendCount: 0,
				totalDividendAmount: 0,
				todayDividendAmount: 0,
				yesterdayDividendAmount: 0,
				totalPurchaseRate: 0,
				todayPurchaseRate: 0,
				yesterdayPurchaseRate: 0,
				totalInstallCount: 0,
				todayInstallCount: 0,
				yesterdayInstallCount: 0
			},
			showWithdrawConfirmModal: false
		}
	},
	beforeMount() {
		this.getReferAccountInfo(() => {
			const sessionId = localStorage.getItem('sessionId');
			const lastVerify = localStorage.getItem('lastVerify');
			const now = parseInt(new Date().getTime() / 1000);
			if (parseInt(now) - parseInt(lastVerify) < 24 * 3600) {
				this.isLogin = true;
				if (this.showOverviewPage === true) {
					this.getOverviewData()
					this.activeKey = '0'
				} else {
					this.getReferenceRecord(sessionId)
					this.activeKey = '1'
				}
				return
			}
			postRequest('airport/auth/verifySessionId', {sessionId: sessionId}, (result, data) => {
				if (result === 'success') {
					this.isLogin = true;
					localStorage.setItem('lastVerify', now);

					if (this.showOverviewPage === true) {
						this.getOverviewData()
						this.activeKey = '0'
					} else {
						this.getReferenceRecord(sessionId)
						this.activeKey = '1'
					}
				} else {
					localStorage.removeItem('sessionId')
					localStorage.removeItem('lastVerify')
				}
			})
		})


	},
	methods: {
		onLoginSuccess() {
			this.isLogin = true;
		},
		getReferenceRecord(sessionId) {
			postRequest('airport/reference/getReferenceRecord', {
				sessionId: sessionId,
				page: this.pageCount
			}, (result, data) => {

				if (result === 'success') {
					this.referenceRecordList = data.msg.referenceRecrod
					this.totalCount = data.msg.count
					this.referenceTCount = data.msg.tcount
					this.referenceYCount = data.msg.ycount
				}
			})
		},
		onPageChanged(data) {
			this.pageCount = data
			const sessionId = localStorage.getItem('sessionId');
			this.getReferenceRecord(sessionId)

		},
		onDividendPageChanged(data) {
			this.dividendPageCount = data
			this.getDividendRecord()
		},
		async onTabChanged(data) {
			if (data === '0') {
				this.getOverviewData()
				return
			}
			if (data === '1' && this.installList.length === 0) {
				const sessionId = localStorage.getItem('sessionId');
				this.getReferenceRecord(sessionId)
				return
			}
			if (data === '2' && this.installList.length === 0) {
				this.getInstallList()
				return
			}
			if (data === '3' && this.dividendList.length === 0) {
				this.getDividendRecord()
				return
			}
			if (data === '4') {
				this.getReferAccountInfo()
				await this.getDividendInfo()
				return
			}
		},
		getDividendRecord() {
			const sessionId = localStorage.getItem('sessionId')
			if (sessionId) {
				postRequest('airport/reference/getDividendRecordByPage', {
					sessionId: sessionId,
					page: this.dividendPageCount
				}, (result, data) => {
					if (result === 'success') {
						this.dividendList = data.msg.records
						this.totalDividendAmount = roundNumber(data.msg.totalAmount, 2)
						this.totalDividendCount = data.msg.totalCount
						this.todayDividendCount = data.msg.tCount
						this.todayDividendAmount = roundNumber(data.msg.tAmount, 2)
						this.yesterdayDividendCount = data.msg.yCount
						this.yesterdayDividendAmount = roundNumber(data.msg.yAmount, 2)

					}
				})
			}
		},
		getDurationDisplay(duration) {
			return Math.floor(duration / (24 * 3600)) + ' 天'
		},
		getUsdtDisplay(usdt) {
			return Math.floor(usdt) / 100
		},
		// withdrawAll() {
		//
		// },
		async getDividendInfo() {
			const {err, result} = await getDividendInfo()
			if (err === false && result?.code === 200) {
				this.withdrawableDividend = (result?.msg?.totalNonwithdrawn / 100).toFixed(2)
			}
		},
		getReferAccountInfo(callback) {

			const sessionId = localStorage.getItem('sessionId')
			if (!sessionId) {
				return
			}
			const lastVerify = localStorage.getItem('lastVerify');
			const now = parseInt(new Date().getTime() / 1000);
			if (parseInt(now) - parseInt(lastVerify) < 24 * 3600) {
				this.isLogin = true;
			}
			if (sessionId) {
				postRequest('airport/reference/getReferAccountInfo', {sessionId: sessionId}, (result, data) => {
					if (result === 'success') {
						this.email = data.msg.email
						this.bep20WalletAddress = data.msg.bep20WalletAddress
						this.trc20WalletAddress = data.msg.trc20WalletAddress
						if (data.msg.showInstallState === true) {
							this.showInstallState = true
						}
						if (data.msg.showOverviewPage === true) {
							this.showOverviewPage = true
						}
					}
					if (callback) {
						callback()
					}
				})
			}
		},
		onEditWallet() {
			this.showEditWalletModal = true
		},
		onEditWalletCancel() {
			this.showEditWalletModal = false
		},
		onEditWalletClose(trc20Address, bep20Address) {
			this.trc20WalletAddress = trc20Address
			this.bep20WalletAddress = bep20Address
			this.showEditWalletModal = false
		},
		getInstallList() {
			const sessionId = localStorage.getItem('sessionId')
			if (sessionId) {
				postRequest('airport/reference/getInstallList', {
					sessionId: sessionId,
					page: this.installPageCount
				}, (result, data) => {
					if (result === 'success') {
						if (data.msg && data.msg.list && Array.isArray(data.msg.list)) {
							this.installList = data.msg.list
							this.installTotalCount = data.msg.count
							this.installYCount = data.msg.ycount
							this.installTCount = data.msg.tcount
							this.tInstallRate = (data.msg.tInstallRate * 100).toFixed(2) + '%'
							this.yInstallRate = (data.msg.yInstallRate * 100).toFixed(2) + '%'
							this.totalInstallRate = (data.msg.totalInstallRate * 100).toFixed(2) + '%'
						}
					}
				})
			}

		},
		onInstallPageChanged(data) {
			this.installPageCount = data
			this.getInstallList()
		},
		refreshPage(type) {
			if (type === 'reference') {

				const sessionId = localStorage.getItem('sessionId');
				if (sessionId) {
					this.getReferenceRecord(sessionId)
				}
				return
			}
			if (type === 'install') {
				this.getInstallList()
				return
			}
			if (type === 'dividend') {
				this.getDividendRecord()
				return
			}

			if (type === 'overview') {
				this.getOverviewData()
				return
			}
		},
		getOverviewData() {
			const sessionId = localStorage.getItem('sessionId')
			if (sessionId) {
				postRequest('airport/reference/getReferenceOverview', {
					sessionId: sessionId,
					page: this.installPageCount
				}, (result, data) => {
					if (result === 'success') {
						if (data.msg) {
							this.overviewData.totalReferenceCount = data.msg.totalReferenceCount
							this.overviewData.todayReferenceCount = data.msg.todayReferenceCount
							this.overviewData.yesterdayReferenceCount = data.msg.yesterdayReferenceCount
							this.overviewData.totalRegCount = data.msg.totalRegCount
							this.overviewData.toadyRegCount = data.msg.toadyRegCount
							this.overviewData.yesterdayRegCount = data.msg.yesterdayRegCount

							this.overviewData.totalInstallCount = data.msg.totalInstallCount
							this.overviewData.todayInstallCount = data.msg.todayInstallCount
							this.overviewData.yesterdayInstallCount = data.msg.yesterdayInstallCount

							this.overviewData.totalRegRate = parseFloat((data.msg.totalRegRate * 100).toFixed(2))
							this.overviewData.todayRegRate = parseFloat((data.msg.todayRegRate * 100).toFixed(2))
							this.overviewData.yesterdayRegRate = parseFloat((data.msg.yesterdayRegRate * 100).toFixed(2))

							this.overviewData.totalDividendCount = data.msg.totalDividendCount
							this.overviewData.todayDividendCount = data.msg.todayDividendCount
							this.overviewData.yesterdayDividendCount = data.msg.yesterdayDividendCount

							this.overviewData.totalPurchaseRate = parseFloat((data.msg.totalPurchaseRate * 100).toFixed(2))
							this.overviewData.todayPurchaseRate = parseFloat((data.msg.todayPurchaseRate * 100).toFixed(2))
							this.overviewData.yesterdayPurchaseRate = parseFloat((data.msg.yesterdayPurchaseRate * 100).toFixed(2))

						}
					}
				})
			}
		},
		async onWithdrawClicked() {
			this.showWithdrawConfirmModal = true
			// const {err, result} = await withdrawDividend()
			// if (err === false) {
			// 	if (result?.code === 202) {
			// 		this.$message.info('最小提现额：10USDT');
			// 	}
			// 	if (result?.code === 200) {
			// 		this.$message.success('提现成功！区块确认可能需要几分钟，请等待转账确认。');
			// 	}
			// }
		},
		onCloseWithdrawConfirmModal() {
			this.showWithdrawConfirmModal = false
		}
	}
}
</script>

<style scoped>

.reference-page-container {
	min-height: 100vh;
	position: relative;
	background-color: #191123;
	overflow-x: hidden;
	width: 100vw;
}

.manual-page-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: start;
	padding: 0 3em;
}

.manual-page-panel-content-container {

}

.manual-collapse-header {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 900px) {
	.manual-page-container {
		padding: 0 0.8em !important;
	}
}

.reference-table-container {
	width: 100%;
	display: table;
	color: #E6D5B8;
}

.reference-table-row {
	display: table-row;
}

.reference-table-cell {
	display: table-cell;
	padding: 0.5em 1em;
	border-bottom: 1px solid #E6D5B8;
	text-align: left;
}

.reference-total-line {
	width: 100%;

	color: #E6D5B8;
	text-align: left;
	padding-bottom: 0.4em;
}

.dividend-record-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	color: #E6D5B8;
	align-items: start;
	border-bottom: 1px solid #E6D5B8;
	padding: 0.4em;
}

.dividend-record-title {
	font-size: 1.2em;
	padding-bottom: 0.4em;
	color: #FF8900;
}

.is-not-received {
	color: #FF8900 !important;
	width: 100%;
	text-align: end;
	font-size: 1.2em;
}

.is-received {
	width: 100%;
	text-align: end;
	font-size: 1.2em;
}

.dividend-tab-container {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
}

.dividend-btn-container {
	width: 100%;
	margin-top: 1em;
}

.withdraw-dividend-btn {

}

.dividend-wallet-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: start;
	border: 1px solid #FF8900;
	border-radius: 0.4em;
	padding: 0.6em;
}

.account-info-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: start;
	color: #FF8900;
}

.account-info-line {
	margin-bottom: 0.4em;
	font-size: 1.4em;
}

.dividend-wallet-title {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.4em;
	margin-bottom: 0.4em;
	width: 100%;
}

.dividend-wallet-content-line {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.4em;
	margin-bottom: 0.4em;
	width: 100%;
}

.overview-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr; /* 列宽度设置为相等的三列 */
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
}


.overview-grid-cell {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(230, 213, 184, 0.2);
}

.overview-content {
	font-size: 1.4em;
}

.overview-cell-title {
	padding-top: 0.4em;
}

.table-tool-bar {
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: start;
	width: 100%;
	margin-bottom: 0.4em;
}

.table-toolbar-btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
}

.table-toolbar-img {
	width: 1.2em;
	margin-right: 0.2em;
}

.table-toolbar-btn-text {
	font-size: 1.2em;
	color: #E6D5B8;
	white-space: nowrap;
}

.overview-tab-content-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr; /* 列宽度设置为相等的三列 */
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
}

@media only screen and (max-width: 900px) {
	.overview-container {
		grid-template-columns: 1fr 1fr; /* 列宽度设置为相等的三列 */
		gap: 0.4em; /* 网格间距 */
	}

	.overview-tab-content-container {

		grid-template-columns: 1fr 1fr; /* 列宽度设置为相等的三列 */
		gap: 0.4em; /* 网格间距 */
	}
}

.withdraw-dividend-btn {
	background-color: #FF8900;
	color: #191123;
	margin-bottom: 0.4em;
	font-size: 1.4em;
	padding: 0.1em 0.4em;
	cursor: pointer;
	border-radius: 0.2em;
}

.withdraw-dividend-btn:active {
	transform: scale(0.95);
}

</style>