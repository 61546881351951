<template>
	<div class="general-modal-bg">
		<div class='general-modal-container'>

			<div class='general-modal-header-container'>
				<div>填写工单信息</div>
				<img class="general-modal-header-close-btn" src="../images/close_white.svg" alt="close" @click="this.onClose">
			</div>
			<div class='general-modal-label'>工单标题 *</div>
			<input class='general-modal-input-text' type="text" placeholder='工单标题' v-model='this.ticketSubject'/>
			<div class='general-modal-label'>选择工单类型 *</div>
			<a-select
				class="general-modal-selector"
				v-model:value="ticketType"
				:size="'large'"
				style="width: 200px"
				:options="ticketTypeOptions"
			></a-select>
			<div class='general-modal-label'>工单内容 *</div>
			<textarea class='general-modal-input-textarea' type="text"
			          placeholder='请在此简单明确的描述您要反馈的具体信息' v-model='this.ticketContent' rows="4"/>
			<div class='general-modal-label'>上传图片</div>
			<div class="update-images-container">
				<template v-for="item in this.ticketImageList" :key="item">
					<div class="uploaded-image-container">
						<img class="upload-btn-container-image-uploaded" :src="item" alt="uploaded"/>
					</div>
				</template>
			</div>

			<div class="upload-btn-container" @click="this.onUploadImage" v-if="this.inUploadingImage === false">
				<img class="upload-btn-container-image" src="../images/plus_white.svg" alt="plus">
				<div class="upload-btn-container-text">点击添加</div>
			</div>
			<div class="upload-progress-bar-container" v-if="this.inUploadingImage === true">
				<div class="upload-progress-text">正在上传 {{this.uploadingProgressPercentage}}%</div>
				<div class="upload-progress-bar-bg">
					<div class="upload-progress-bar" :style="this.getProgressBarStyle()"/>
				</div>
			</div>
			<div class="ticket-create-btn-row">

				<div class="submit-ticket-btn" @click="this.submitTicket">提交</div>
			</div>
<!--			<div class='upload-btn'>-->
<!--				<img class="upload-image-icon" src="../images/upload.svg" alt="upload">选择图片-->
<!--			</div>-->
			<!-- 隐藏的文件输入 -->
			<input type="file" ref="fileInput" @change="handleFileChange" style="display: none"
			       accept="image/png, image/jpeg">
		</div>
	</div>
</template>
<script>
import {uploadFile, submitTicket} from '@/utils/methods';

export default {
	name: "CreateTicketModal",
	data() {
		return {
			ticketId: null,
			ticketSubject: '',
			ticketContent: '',
			ticketType: '',
			ticketTypeOptions: [
				{
					value: '节点连接问题',
					label: '节点连接问题',
				},
				{
					value: '支付问题',
					label: '支付问题',
				},
				{
					value: '客户端问题',
					label: '客户端问题',
				},
				{
					value: '其他',
					label: '其他',
				}
			],
			inUploadingImage: false,
			uploadingProgressPercentage: 0,
			ticketImageList: []
		}
	},
	async mounted() {
		this.ticketImageList = []
		this.ticketId = null
	},
	methods: {
		getProgressBarStyle() {
			return {
				'width': this.uploadingProgressPercentage + '%'
			}
		},
		onUploadImage() {
			if (this.ticketImageList.length >= 4) {
				this.$message.info('达到工单图片上传上限');
				return
			}
			this.$refs.fileInput.click();
		},
		// 处理文件选择
		async handleFileChange(event) {
			const file = event.target.files[0];
			if (file) {
				const formData = new FormData();
				formData.append('file', file);
				formData.append('ticketId', this.ticketId);
				this.inUploadingImage = true
				await uploadFile('airport/ticket/uploadImage', formData, this.updateUploadImageInProgress, this.updateUploadImageSuccess, this.updateUploadImageFailed)
			}
		},
		updateUploadImageInProgress(percentage) {
			this.uploadingProgressPercentage = percentage
		},
		updateUploadImageSuccess(data) {
			this.$message.success('上传成功')
			this.inUploadingImage = false
			if (data?.msg?.ticketId) {
				this.ticketId = data?.msg?.ticketId
			}
			if (data?.msg?.imageUrlList) {
				this.ticketImageList = data?.msg?.imageUrlList
			}
		},
		updateUploadImageFailed() {
			this.$message.error('上传失败')
			this.inUploadingImage = false

		},
		onClose() {
			this.$emit('close');
		},
		async submitTicket() {
			if(!this.ticketSubject) {
				return this.$message.error('工单标题不能为空')
			}
			if(!this.ticketType) {
				return this.$message.error('工单类型不能为空')
			}
			if(!this.ticketContent) {
				return this.$message.error('工单内容不能为空')
			}

			const {err, result} = await submitTicket(this.ticketSubject, this.ticketContent, this.ticketType, this.ticketId)

			if (err === false) {
				if (result?.code === 200) {
					this.$emit('submitted');
					return this.$message.success('提交成功')
				}
			}
			this.$message.error('提交失败')
		}
	}
}
</script>

<style scoped>

input::-webkit-input-placeholder {
	color: #64563f;
}

/* Firefox */
input::-moz-placeholder {
	color: #64563f;
}

/* Internet Explorer 10-11 */
input::-ms-input-placeholder {
	color: #64563f;
}

/* Microsoft Edge */
input::-ms-input-placeholder {
	color: #64563f;
}

/* 其他浏览器 */
input::placeholder {
	color: #64563f;
}

textarea::-webkit-input-placeholder {
	color: #64563f;
}

/* Firefox */
textarea::-moz-placeholder {
	color: #64563f;
}

/* Internet Explorer 10-11 */
textarea::-ms-input-placeholder {
	color: #64563f;
}

/* Microsoft Edge */
textarea::-ms-input-placeholder {
	color: #64563f;
}

/* 其他浏览器 */
textarea::placeholder {
	color: #64563f;
}

.upload-btn {
	font-size: 1em;
	background-color: #E6D5B8;
	padding: 0.2em 0.6em;
	color: #2c3e50;
	width: fit-content;
	border-radius: 0.2em;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 0.6em 0;
}

.upload-btn:active {
	transform: scale(0.95);
}

.upload-image-icon {
	width: 1.2em;
}

.upload-btn-container {
	border: 1px solid #E6D5B8;
	width: 8em;
	height: 8em;
	margin: 0.6em 0;
	border-radius: 0.2em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 0.4em;
	box-sizing: border-box;
}

.uploaded-image-container{
	border: 1px solid #E6D5B8;
	width: 8em;
	height: 8em;
	margin: 0.6em 0.6em 0.6em 0;
	border-radius: 0.2em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 0.4em;
	box-sizing: border-box;
}

.upload-btn-container-image{
	width: 3em;
}

.upload-btn-container-image-uploaded{
	max-width: 100%;
	max-height: 100%;
}

.upload-progress-text{
	color: #E6D5B8;
}

.upload-btn-container-text{
	color: #E6D5B8;
}

.update-images-container{
	display: flex;
	flex-direction: row;
	width: 100%;
	box-sizing: border-box;
}

.upload-progress-bar-container{
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
}

.upload-progress-bar-bg{
	width: 100%;
	height: 5px;
	background-color: #695b44;
	position: relative;
}

.upload-progress-bar{
	position: absolute;
	top: 0;
	left: 0;
	background-color: #E6D5B8;
	height: 5px;
}

.submit-ticket-btn{
	background-color: #E6D5B8;
	color: #2c3e50;
	width: fit-content;
	padding: 0.4em 0.8em;
	border-radius: 0.2em;
	font-size: 1.2em;
	cursor: pointer;
	line-height: 1.2em;
}


.submit-ticket-btn:active {
	transform: scale(0.95);
}

.ticket-create-btn-row{
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1em 0;
}
</style>