<template>
	<div class='sl-confirm-modal-bg' @click="onBgClick($event)">
		<div class='sl-confirm-modal'>
			<div class='sl-confirm-modal-container'>
				<div class='sl-confirm-modal-title'>请确认提现信息</div>

				<div class="confirm-modal-content-container">
					<div class="confirm-modal-content-container-label">选择转账网络：</div>
					<div class="confirm-modal-radio-container">
						<div class="confirm-modal-radio-selection-container" @click="this.onChainTypeClicked('BSC')">
							<img v-if="this.selectedChain !== 'BSC'" class="confirm-modal-radio-img"
							     src="../images/radio_unselected.svg" alt="radio">
							<img v-if="this.selectedChain === 'BSC'" class="confirm-modal-radio-img"
							     src="../images/radio_selected.svg" alt="radio">
							<img class="confirm-modal-radio-img" src="../images/crypto/bsc.svg" alt="bsc">
							<div class="confirm-modal-content-container-radio-text">BSC</div>
						</div>
						<div class="confirm-modal-radio-selection-container" @click="this.onChainTypeClicked('TRX')">
							<img v-if="this.selectedChain !== 'TRX'" class="confirm-modal-radio-img"
							     src="../images/radio_unselected.svg" alt="radio">
							<img v-if="this.selectedChain === 'TRX'" class="confirm-modal-radio-img"
							     src="../images/radio_selected.svg" alt="radio">
							<img class="confirm-modal-radio-img" src="../images/crypto/trx.svg" alt="trx">
							<div class="confirm-modal-content-container-radio-text">TRX</div>
						</div>
					</div>
					<div class="confirm-modal-content-container-label">收款地址：</div>
					<div v-if="this.selectedChain === 'TRX'" class="confirm-modal-content-container-text">
						{{ this.trxReceiverAddress || '未设置TRX链收款地址' }}
					</div>
					<div v-if="this.selectedChain === 'BSC'" class="confirm-modal-content-container-text">
						{{ this.bscReceiverAddress || '未设置BSC链收款地址' }}
					</div>

					<div class="confirm-modal-content-container-label">邮箱验证：</div>
					<div class="confirm-withdraw-modal-send-vcode">
						<div class='sl-login-email-field-container'>
							<input class='sl-login-input-email' type="email" placeholder='请在此输入您的邮箱' :value=this.email disabled>
							<div class='sl-login-send-vcode-btn disable-select' @click="this.sendVcode">{{this.getSendVcodeBtnText()}}</div>
						</div>
					</div>
					<div class="confirm-modal-content-container-label">验证码：</div>
					<div class="confirm-withdraw-modal-send-vcode">
						<div class='sl-login-email-field-container'>
							<input class='sl-login-input-email-full' type="email" placeholder='请在此输入验证码' v-model="this.vcode">
						</div>
					</div>

					<div class="confirm-modal-check-container" @click="this.onConfirmReceiverAddress">
						<img v-if="this.confirmReceiverAddress === false" class="confirm-modal-checkbox" src="../images/checkbox_unchecked.svg" alt="checkbox">
						<img v-if="this.confirmReceiverAddress === true" class="confirm-modal-checkbox" src="../images/checkbox_checked.svg" alt="checkbox">
						<div>我确认收款地址无误。</div>
					</div>
					<div class="confirm-modal-check-container" @click="this.onConfirmGasPrice">
						<img v-if="this.confirmGasPrice === false" class="confirm-modal-checkbox" src="../images/checkbox_unchecked.svg" alt="checkbox">
						<img v-if="this.confirmGasPrice === true" class="confirm-modal-checkbox" src="../images/checkbox_checked.svg" alt="checkbox">
						<div>我确认提现将消耗 1 USDT 转账gas费。</div>
					</div>


				</div>

				<div class='sl-confirm-modal-btn-container'>
					<a-button type="primary" size="middle" @click="onCancel"
					          style="color: #FF8900;background-color: #191123FF;margin-right: 0.5em;">
						取消
					</a-button>
					<a-button type="primary" size="middle" @click="onConfirm">
						确认提现
					</a-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {getWalletInfo, sendWithdrawDividendVcode, withdrawDividend} from '@/utils/methods'

export default {
	name: "WithdrawConfirmModal",
	data() {
		return {
			vcode: '',
			password: '',
			confirmPassword: '',
			sendEmailCountDown: -1,
			countDownInterval: null,
			selectedChain: 'BSC',
			trxReceiverAddress: null,
			bscReceiverAddress: null,
			confirmReceiverAddress: false,
			confirmGasPrice: false
		}
	},
	props: ['email'],
	async mounted() {
		await this.getWalletInfo()
	},
	computed: {
		getVcodeCountdown() {
			return this.$store.state.vcodeCountdown
		}
	},
	methods: {
		async sendVcode() {
			if (this.selectedChain === 'BSC' && !this.bscReceiverAddress) {
				return this.$message.error('请设置收款地址')
			}
			if (this.selectedChain === 'TRX' && !this.trxReceiverAddress) {
				return this.$message.error('请设置收款地址')
			}
			if (this.getVcodeCountdown >= 0) {
				return
			}
			const {err, result} = await sendWithdrawDividendVcode()
			if (err === false) {
				if (result?.code === 200) {
					this.$message.success('验证码已发出')
					this.$store.dispatch('setVcodeCountdown', 60)
					this.countDownInterval = setInterval(() => {
						this.$store.dispatch('vcodeCountdown')
						if (this.getVcodeCountdown < 0 && this.countDownInterval) {
							clearInterval(this.countDownInterval)
							this.countDownInterval = null
						}
					}, 1000)
					return
				}
				if (result?.code === 205) {
					this.$message.error('验证码过期')
				}
			}

		},
		getSendVcodeBtnText() {
			const countdownNum = this.getVcodeCountdown
			if (countdownNum <= 0) {
				return '发送验证码'
			}
			return `${countdownNum}秒后重发`
		},
		onConfirmReceiverAddress() {
			this.confirmReceiverAddress = !this.confirmReceiverAddress
		},
		onConfirmGasPrice() {
			this.confirmGasPrice = !this.confirmGasPrice
		},
		onCancel() {
			this.$emit('cancel')
		},
		async onConfirm() {
			if(this.selectedChain === 'BSC' && !this.bscReceiverAddress) {
				return this.$message.error('请设置收款地址')
			}
			if(this.selectedChain === 'TRX' && !this.trxReceiverAddress) {
				return this.$message.error('请设置收款地址')
			}
			if (!this.vcode) {
				return this.$message.error('请输入验证码')
			}
			if(!this.confirmReceiverAddress) {
				return this.$message.error('请勾选“确认收款地址无误”')
			}
			if(!this.confirmGasPrice) {
				return this.$message.error('请勾选“确认转账gas费”')
			}
			const {err, result} = await withdrawDividend(this.vcode)
			if (err === false) {
				if (result?.code === 200) {
					this.$message.success('提现成功！')
					return this.$emit('confirm')
				}
				if (result?.code === 204) {
					return this.$message.error('验证码不存在')
				}
				if (result?.code === 205) {
					return this.$message.error('验证码已过期')
				}
				if (result?.code === 206) {
					return this.$message.error(result?.msg)
				}
			}

		},
		onBgClick(event) {
			event.stopPropagation()
		},
		onChainTypeClicked(type) {
			this.selectedChain = type
		},
		async getWalletInfo() {
			const {err, result} = await getWalletInfo()

			if (err === false) {
				if (result?.code === 200) {
					this.trxReceiverAddress = result?.msg?.trc20WalletAddress
					this.bscReceiverAddress = result?.msg?.bep20WalletAddress
				}
			}
		}
	}
}
</script>

<style scoped>
input {
	color: #FF8900;
}

.confirm-modal-content-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
	margin: 1em 0;
}

@media (max-width: 900px) {
	.sl-confirm-modal {
		width: 100% !important;
		border-radius: 0 !important;
		top: 0 !important;
		height: 100%;
	}
}

.sl-confirm-modal {
	width: 40em;
	position: fixed;
	background-color: #191123;
	transform: translate(-50%, 0%);
	left: 50%;
	top: 5em;
	border-radius: 0.4em;
	/* border: 1px solid rgba(255, 137, 0, 0.44); */
	box-shadow: 0 0 8px 8px rgba(255, 137, 0, 0.15);
	padding: 0.8em;
}

.swiper-slide-active > .sl-avatar-name {
	text-shadow: 2px 2px 5px red;
}

.sl-confirm-modal-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: #191123;
}

.sl-confirm-modal-title {
	font-size: 1.2em;
	width: 100%;
	text-align: left;
	color: #FF8900;
	margin-bottom: 0.2em;
}
.confirm-modal-content-container-label {
	color: #FF8900;
	font-size: 1.2em;
	margin-bottom: 0.2em;
}

.confirm-modal-content-container-text {
	color: #FF8900;
	font-size: 1.2em;
	margin-bottom: 1em;
}

.confirm-modal-content-container-radio-text{
	color: #FF8900;
	font-size: 1.2em;
}

.sl-confirm-send-vcode-btn {
	background-color: #ff8900;
	font-size: 1em;
	color: #3d3020;
	border-radius: 0.2em;
	padding: 0 0.3em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: fit-content;
	word-break: keep-all;
}

.sl-confirm-send-vcode-btn:active {
	transform: scale(0.9);
}

.sl-confirm-input-email {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.2em;
	background: transparent;
	margin-top: 0.4em;
	flex: 1 1;
}

input.sl-confirm-input:focus {
	border-bottom: 2px solid #FF8900;
}

input.sl-confirm-input-email:focus {
	border-bottom: 2px solid #FF8900;
}

.sl-confirm-email-field-container {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.sl-confirm-modal-bg {
	position: fixed;
	width: 100vw;
	/* backdrop-filter: blur(8px) opacity(100%) brightness(90%); */
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.4);

	backdrop-filter: blur(3px) opacity(100%) brightness(90%);
	top: 0;
	left: 0;
	height: 100vh;
}

.sl-confirm-modal-close-container {
	width: 100%;
	text-align: right;
}

.sl-confirm-modal-bg-container {
	width: 100vw;
	height: 100vh;
}

.sl-confirm-modal-btn-container {
	text-align: right;
	width: 100%;
}

.confirm-modal-radio-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	box-sizing: border-box;
}

.confirm-modal-radio-selection-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex: 1;
	margin-bottom: 1em;
	padding: 1em;
	cursor: pointer;
}

.confirm-modal-radio-img {
	width: 1.2em;
	margin: 0 0.4em;
}
.sl-login-email-field-container {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.sl-login-email-field-container-full{

}

.sl-login-send-vcode-btn {
	background-color: #ff8900;
	font-size: 1em;
	color: #3d3020;
	border-radius: 0.2em;
	padding: 0 1.4em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: fit-content;
	word-break: keep-all;
}

.confirm-withdraw-modal-send-vcode{
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-bottom: 0.2em;
}

.sl-login-input-email{
	background-color: transparent;
	flex: 1;
	font-size: 1.2em;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid #FF8900;
	margin-right: 1em;
}

.sl-login-input-email-full{
	background-color: transparent;
	flex: 1;
	font-size: 1.2em;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid #FF8900;
}

.sl-login-send-vcode-btn:active {
	transform: scale(0.95);
}

.confirm-modal-check-container{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	color: #FF8900;
	font-size: 1.2em;
	margin-top: 1em;
}

.confirm-modal-checkbox{
	width: 1.2em;
	margin-right: 0.4em;
	cursor: pointer;
}

</style>