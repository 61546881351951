<template>

	<div class="customer-service-page-container">
		<LoginModal v-if="isLogin === false" :canShowLogo="false" @onLoginSuccess="this.onLoginSuccess"
		            :loginUrl="'customerServicePanel/login'"/>
		<div v-if="isLogin === true" class="cs-main-container">
			<img src="../images/menu.svg" alt="menu" class="cs-menu-button" @click="this.onShowMenuClicked">
			<div class="cs-menu-container">
				<template v-for="item in this.topicList" :key="item.feedbackTopicId">

					<div class="topic-menu-item" @click="this.onTopicItemClicked(item)">
						<div class="topic-menu-email">
							{{ this.getTopicCreatorEmail(item) }}
						</div>
						<div class="topic-menu-item-user-description">
							{{ this.getTopicDescription(item) }}
						</div>
						<div class="topic-menu-item-user-description">
							{{ item.type === 'vpn' ? '加速器' : '机场' }}
						</div>
						<div class="topic-menu-item-state" v-if="this.getTopicUpdateTime(item)">
							更新时间：{{ this.getTopicUpdateTime(item) }}
						</div>
						<div class="topic-menu-item-state">
							创建时间：{{ item.createdTimeString }}
						</div>
						<div class="topic-menu-item-state"
						     :style="this.getTicketStatusStyle(item)">
							{{ this.getTicketState(item) }}
						</div>
					</div>
				</template>
			</div>
			<div v-if="this.showMenuMobile === true" class="cs-menu-container-mobile">
				<img src="../images/close.svg" alt="close" class="cs-close-menu-btn" @click="this.onCloseMenuClicked">
				<template v-for="item in this.topicList" :key="item.feedbackTopicId">

					<div class="topic-menu-item" @click="this.onTopicItemClicked(item)">
						<div class="topic-menu-email">
							{{ this.getTopicCreatorEmail(item) }}
						</div>
						<div class="topic-menu-item-user-description">
							{{ this.getTopicDescription(item) }}
						</div>
						<div class="topic-menu-item-user-description">
							产品：{{ item.type === 'vpn' ? '加速器' : '机场' }}
						</div>
						<div class="topic-menu-item-state" v-if="this.getTopicUpdateTime(item)">
							更新时间：{{ this.getTopicUpdateTime(item) }}
						</div>
						<div class="topic-menu-item-state">
							创建时间：{{ item.createdTimeString }}
						</div>
						<div class="topic-menu-item-state"
						     :style="this.getTicketStatusStyle(item)">
							{{ this.getTicketState(item) }}
						</div>
					</div>
				</template>
			</div>
			<div class="cs-topic-content">
				<div v-if="this.currentDisplayTopic !== null" class="cs-topic-content-view">
					<div class="cs-topic-detail-email">用户邮箱：{{ this.getFeedbackEmail() }}</div>
					<div class="cs-topic-detail-email">工单类型：{{ this.getTicketSource() }}</div>
					<div class="cs-topic-detail-email">创建时间：{{ this.currentDisplayTopic.createdTimeString }}</div>
					<div class="cs-topic-detail-email" v-if="this.userReferByCode">推荐码：{{ this.userReferByCode }}</div>
					<div class="cs-topic-detail-email" v-if="this.userReferByEmail">推荐人邮箱：{{ this.userReferByEmail }}</div>
					<div class="cs-topic-detail-email">注册时间：{{ this.userRegisterTime }}</div>
					<div class="cs-topic-detail-email">到期时间：{{ this.userPlanExpireTime || '无' }}</div>
					<div class="cs-topic-detail-email">支付记录：{{ this.getUserPaymentsDisplay() }}</div>
					<div class="cs-topic-detail-type-title">反馈类型：</div>
					<template v-if="this.currentDisplayTopic.type === 'vpn'">
						<template v-for="(item, $index) in this.currentDisplayTopic.topicType" :key="item.key">
							<div class="cs-topic-detail-type-item">
								{{ ($index + 1) + '. ' + item.label }}
							</div>
						</template>
					</template>
					<div v-if="this.currentDisplayTopic.type === 'airport'">
						{{this.currentDisplayTopic?.ticketType}}
					</div>


					<div class="cs-topic-detail-user-des-title">问题描述：</div>
					<div class="cs-topic-detail-user-des-content">{{ this.getFeedbackContent() }}</div>
					<div class="cs-chat-content-container">
						<template v-for="(item) in this.currentDisplayChat" :key="item.feedbackChatRecordId">
							<div class="cs-chat-content-wrapper">
								<div
									:class="[item.isClientChat ? 'cs-topic-detail-client-chat' : 'cs-topic-detail-cs-chat']"
									style="width: 100%;text-align: start;">
									{{ (item.isClientChat === true ? '用户：\n' : '我：\n') + item.chatContent }}
									<div style="width: 100%;text-align: right;">{{ item.createdTimeString }}</div>
								</div>
							</div>

						</template>
					</div>


					<div class="editor-container">
						<input class='cs-input' type="text" placeholder='请在此输入信息' v-model="this.toSendMsg"/>
						<a-button type="primary" size="large" @click="sendMsgReply"
						          style="color: #191123FF;background-color: #FF8900;">
							发送
						</a-button>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import {getRequestAsync, hideLoading, postRequestAsync, showLoading, convertTimestampToTimeString} from "@/utils/methods";
import LoginModal from "../components/LoginModal.vue";
import moment from 'moment-timezone';

export default {
	name: "CustomerServicePage",
	components: {
		LoginModal
	},
	data() {
		return {
			isLogin: false,
			topicList: [],
			currentDisplayTopic: null,
			currentDisplayChat: [],
			toSendMsg: '',
			showMenuMobile: false,
			userReferByCode: null,
			userReferByEmail: null,
			userRegisterTime: null,
			userPlanExpireTime: null,
			usrePaymentRecords: []
		}
	},
	async beforeMount() {
		this.isLogin = await this.checkLogin()
		if (this.isLogin) {
			await this.getAllTopics(true)
		}
	},
	methods: {
		getTicketSource() {
			if (this.currentDisplayTopic?.type === 'vpn') {
				return '加速器'
			}
			return '机场'
		},
		getFeedbackType() {

		},
		getFeedbackEmail () {
			if (this.currentDisplayTopic?.type === 'vpn') {
				return this.currentDisplayTopic?.email
			}

			return this.currentDisplayTopic?.userEmail
		},
		getFeedbackContent () {
			if (this.currentDisplayTopic?.type === 'vpn') {
				return this.currentDisplayTopic?.userDescription
			}

			return this.currentDisplayTopic?.content
		},
		getTopicCreatorEmail(item) {
			if (item.type === 'vpn') {
				return item.email
			}
			return item.userEmail
		},
		getTopicUpdateTime(item) {
			if (item.type === 'vpn') {
				return item.updatedTimeString
			}
			return item.updateTimeString
		},
		getTopicDescription (item) {
			if (item.type === 'vpn') {
				return item.userDescription
			}
			return item.subject
		},
		getTicketState(item) {
			// 'is-replied': item.topicState === '已回复', 'wait-reply': item.topicState === '等待回复'
			if(item.type === 'vpn') {
				return item.topicState
			}
			if (item.ticketStatus === 'wait_for_reply') {
				return '等待回复'
			}
			if (item.ticketStatus === 'replied') {
				return '已回复'
			}
			if (item.ticketStatus === 'closed') {
				return '已关闭'
			}
		},
		getTicketStatusStyle (item) {
			if(item.type === 'vpn') {
				if (item.topicState === '已回复') {
					return {
						'color': 'green'
					}
				}
				if (item.topicState === '等待回复') {
					return {
						'color': 'red'
					}
				}
			}
			if (item.ticketStatus === 'wait_for_reply') {
				return {
					'color': 'red'
				}
			}
			if (item.ticketStatus === 'replied') {
				return {
					'color': 'green'
				}
			}
			if (item.ticketStatus === 'closed') {
				return {
					'color': '#E6D5B8'
				}
			}
		},
		async checkLogin() {
			showLoading()
			const result = await getRequestAsync('customerServicePanel/verifySession')
			hideLoading()
			if (result.code !== 200) {
				localStorage.removeItem('sessionId')
				localStorage.removeItem('lastVerify')
			}
			return result.code === 200
		},
		onLoginSuccess() {
			this.isLogin = true
		},
		async getAllTopics(isMount = false) {
			showLoading()
			const result = await getRequestAsync('customerServicePanel/getAllTopics')
			hideLoading()
			if (result.code === 200) {
				this.topicList = result.msg
				if (isMount === true && Array.isArray(this.topicList) && this.topicList.length > 0) {
					await this.onTopicItemClicked(this.topicList[0])
				}
			}
		},
		async onTopicItemClicked(item) {
			showLoading()
			const result = await postRequestAsync('customerServicePanel/getTopicDetail', {topicId: item.feedbackTopicId, ticketId: item.ticketId})
			hideLoading()
			if (result.code === 200) {
				this.currentDisplayChat = result.msg
				this.currentDisplayTopic = item
				this.showMenuMobile = false
				if(result?.extra) {
					this.userReferByCode = result?.extra?.referCode || null
					this.userReferByEmail = result?.extra?.referByEmail|| null
					this.userRegisterTime = result?.extra?.registerTime ? convertTimestampToTimeString(result?.extra?.registerTime) : null
					this.userPlanExpireTime = result?.extra?.planExpireTime ? convertTimestampToTimeString(result?.extra?.planExpireTime) : null
					this.usrePaymentRecords = result?.extra?.payments
				}
			} else {
				this.$message.error('获取反馈信息失败');
			}
		},
		getUserPaymentsDisplay () {
			if (Array.isArray(this.usrePaymentRecords) && this.usrePaymentRecords?.length > 0) {
				return `${this.usrePaymentRecords?.length}笔`
			}
			return '无'
		},
		async sendMsgReply() {
			if (this.currentDisplayTopic && this.currentDisplayTopic.ticketId) {
				await this.replyAirportTicket(this.currentDisplayTopic.ticketId)
				return
			}
			if (!this.currentDisplayTopic || !this.currentDisplayTopic.feedbackTopicId) {
				this.$message.error('请选择一个反馈');
				return
			}
			if (!this.toSendMsg) {
				this.$message.error('请输入信息');
				return
			}
			showLoading()
			const result = await postRequestAsync('customerServicePanel/sendTopicReply', {
				topicId: this.currentDisplayTopic.feedbackTopicId,
				msg: this.toSendMsg
			})
			hideLoading()
			if (result.code === 200) {
				debugger
				this.$message.success('发送成功');
				this.toSendMsg = ''
				this.currentDisplayChat = this.msg
			} else {
				this.$message.error('发送失败');
			}
		},
		async replyAirportTicket(ticketId) {
			showLoading()
			const result = await postRequestAsync('airport/ticket/replyAirportTicket', {
				ticketId,
				replyMsg: this.toSendMsg
			})
			hideLoading()

			if (result.code === 200) {
				debugger
				this.$message.success('发送成功');
				this.currentDisplayTopic = result.msg
			}
		},
		onShowMenuClicked () {
			console.log('onShowMenuClicked')
			this.showMenuMobile = true
		},
		onCloseMenuClicked() {
			this.showMenuMobile = false
		}

	}
}
</script>

<style scoped>
.customer-service-page-container {
	min-height: 100vh;
	position: relative;
	background-color: #191123;
	overflow-x: hidden;
	width: 100vw;
}

.cs-menu-container {
	top: 0;
	left: 0;
	flex: 1;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.2);
	padding: 1em 0.8em;
	overflow-y: scroll;
}

.cs-menu-container-mobile {
	top: 0;
	right: 0;
	flex: 1;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.2);
	padding: 1em 0.8em;
	overflow-y: scroll;
	position: absolute;
	visibility: hidden;
	padding-top: 2.4em;
	max-width: 60%;
	overflow-x: hidden;
}

@media only screen and (max-width: 900px) {
	.cs-menu-container {
		display: none;
	}

	.cs-menu-button {
		visibility: visible !important;
	}
	.cs-menu-container-mobile{
		visibility: visible !important;
		z-index: 10;
		background-color: #000000;
	}

	.topic-menu-item-state{
		text-align: start;
	}
}

.cs-menu-button {
	visibility: hidden;
	width: 2em;
	top: 1em;
	right: 1em;
	position: absolute;
	z-index: 3;
}

.cs-close-menu-btn{
	width: 1.4em;
	position: absolute;
	left: 1em;
	top: 1em;
}

.topic-menu-item {
	display: flex;
	flex-direction: column;
	padding: 0.6em;
	border-bottom: 1px solid #E6D5B8;
	align-items: start;
}

.topic-menu-email {
	color: #E6D5B8;
	overflow-x: hidden;
	font-size: 1.2em;
}

.topic-menu-item-user-description {
	color: #E6D5B8;
	overflow-x: hidden;
	font-size: 1.2em;
	white-space: nowrap;
	width: 100%;
	text-align: start;
}

.topic-menu-item-state {

	color: #E6D5B8;
	font-size: 1em;
}

.cs-main-container {
	display: flex;
	flex-direction: row;

	height: 100vh;
	width: 100vw;
	overflow-y: hidden;
	position: relative;
}

.cs-topic-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	color: #E6D5B8;
}

.cs-topic-content-view {
	display: flex;
	flex-direction: column;
	align-items: start;
	padding: 1em;
	font-size: 1.2em;
	position: relative;
	height: 100%;
}

.cs-topic-detail-email {
	margin-bottom: 1em;
}

.cs-topic-detail-user-des-title {
	margin-top: 1em;
}

.editor-container {
	position: absolute;
	bottom: 0;
	padding: 1em;
	display: flex;
	flex-direction: row;
	width: -webkit-fill-available;;
	align-items: end;
	justify-content: center;
}

.cs-input {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.4em;
	background: transparent;
	margin-top: 0.4em;
	flex: 1;
	padding: 0 !important;
	color: #FF8900;
}

input.cs-input:focus {
	border-bottom: 2px solid #FF8900;
}

.cs-chat-content-container {
	padding: 1em 0;
	display: flex;
	flex-direction: column;
	width: -webkit-fill-available;
	align-items: start;
	flex: 1;
	overflow: auto;
	margin-bottom: 2em;
}

.cs-topic-detail-client-chat {
	background-color: rgba(255, 137, 0, 0.2);
	color: #FF8900;
	padding: 0.4em;
	border-radius: 0.4em;
	margin-bottom: 0.8em;
	width: fit-content;
}

.cs-topic-detail-cs-chat {
	background-color: rgba(230, 213, 184, 0.2);
	color: #E6D5B8;
	padding: 0.4em;
	border-radius: 0.4em;
	margin-bottom: 0.8em;
	width: fit-content;
}

.cs-chat-content-wrapper {
	width: -webkit-fill-available;
}

.is-replied {
	color: lawngreen;
}

.wait-reply {
	color: orange;
}
</style>